import React from 'react';
import Wrapper1 from '../components/global/Wrapper1';
import Body from '../components/kaba-FAQ/Body';

const KabaFAQ = () => {
  return (
    <div>
      <Wrapper1 
        name='FAQ'
        bannerTitle='FAQ'
        content={<Body/>}
      />
    </div>
  );
};

export default KabaFAQ;