import React from 'react';

const Body = () => {
  return (
    <div>
      <h1 id="title3">Foire Aux Questions</h1>
      <h1 id="title2">Qui sommes-nous ?</h1>
      <p>KABA DELIVERY est une entreprise qui s’est donnée pour mission de simplifier la vie des personnes via des solutions innovatives de livraison. Pour sa 1ère branche, nous nous sommes associés aux meilleurs restaurants de la capitale afin de livrer leurs menus. Pour ces fins, Kaba a développé une plateforme (essentiellement mobile pour le moment), qui permet de commander dans les restaurants partenaires et de se faire livrer à l’adresse exacte du client. Nos perspectives sont multiples et consisteront à travailler avec des supermarchés, à signer des partenariats BtoB et à faire la livraison de colis sur le plan urbain et à l’international pour atteindre notre vision qui est de devenir la plus grande entreprise de livraison au Togo et dans la sous-région.</p>
      <h1 id="title4">1- Comment commander sur Kaba une première fois ?</h1>
      <p>Pour passer une commande sur Kaba la première fois, l’utilisateur doit créer son compte. Après cette étape, il doit choisir un restaurant dans la liste des restaurants, choisir son menu, sélectionner son adresse puis payer via les modes de paiement disponibles sur Kaba.</p>
      <h1 id="title4">2- Comment le paiement s’effectue-t-il ?</h1>
      <p>Kaba utilise le paiement dématérialisé. L’utilisateur Kaba paye directement en ligne, soit par Flooz ou par T-Money si le restaurant accepte ce mode de paiement. Le paiement par compte bancaire, pour le moment n’est pas encore possible. En fonction, du numéro renseigné, le moyen de paiement adéquat est automatiquement choisi. Avant que l’utilisateur ne valide sa commande, une mini facture affichant les détails de ladite commande lui est présentée. Dès que la validation est faite, le compte de l’utilisateur est immédiatement débité. Par ailleurs, le paiement à l’arrivée est disponible. L’Utilisateur peut choisir un menu et payer à la livraison si le restaurant accepte ce mode de paiement, à condition qu’il fasse l’appoint c’est-à-dire qu’il ait le montant total de sa commande. Kaba veut éviter des difficultés de reliquat à ses utilisateurs, d’où ce choix.</p>
      <h1 id="title4">3- A quoi sert mon compte ? Puis-je le recharger ?</h1>
      <p>Le compte Kaba permet à l’utilisateur d’être affilié au service de manière générale. Il lui permet de passer des commandes et d’avoir toutes les informations inhérentes au service que Kaba offre. Kaba, innove aussi, en permettant à l’utilisateur de recharger son compte via Flooz et T-Money. Ce rechargement lui permet de passer des commandes sans recharger ou effectuer des transferts à chaque instant. Ainsi, vous pouvez recharger votre compte Kaba pour passer des commandes journalièrement, hebdomadairement, mensuellement voire annuellement. Votre compte devient une banque qui finance vos commandes et vos livraisons. C’est en ce sens que Kaba vous simplifie aussi la vie. </p>
      <h1 id="title4">4-Puis-je recharger le compte d’un autre utilisateur ?</h1>
      <p>Oui. Avec notre système de rechargement de compte avant consommation, il vous est possible de recharger le compte d’un autre utilisateur Kaba. Vous pourrez donc aider vos amis, vos connaissances en les dépannant. Une fonctionnalité qu’on a faite sur mesure pour vous.</p>
      <h1 id="title4">5-Tous les modes de paiement sont acceptés ?</h1>
      <p>Le paiement mobile et à l’arrivée sont acceptés par Kaba mais pas par tous les restaurants. En fonction de leurs politiques et des réalités du marché, nous adaptons le paiement pour le bien de tous.</p>
      <h1 id="title4">6-Ai-je droit à des mots de passe ?</h1>
      <p>L’utilisateur a deux mots de passe sur Kaba. Un mot de passe qui lui permet de se connecter (Login) et un autre qui est intitulé ‘’Mot de passe de transaction’’ qui est demandé sur l’interface ‘’KABA PAY’’. Ce dernier permet à l’utilisateur de sécuriser l’utilisation de son solde. NB : Il est le même que celui du login. Un autre mot de passe est généré lorsqu’une commande est passée. C’est celui que l’utilisateur devrait donner au livreur afin d’authentifier sa personne et la commande. Il s’agit du ‘’mot code’’ dans notre jargon. </p>
      <h1 id="title4">7-Comment se passe la livraison ?</h1>
      <p>Lorsque vous passez la commande via l’application, elle est transmise au restaurant qui la traite selon qu’il y’ait possibilité de préparer le repas ou non. Une fois la commande prête, le livreur Kaba affilié au restaurant se chargera de la livrer à l’adresse indiquée. Si le repas ne peut pas être préparé pour une raison inhérente au restaurant ou pas, l’utilisateur en sera informé via des notifications. </p>
      <h1 id="title4">8-Je paye combien à Kaba pour la livraison ?</h1>
      <p>L’utilisateur ou client Kaba est facturé selon la distance qui sépare sa position actuelle et celle du restaurant. Nous facturons en fonction du kilométrage afin que notre politique de pricing soit logique. La grille tarifaire de Kaba se décline comme suit : 0 à 5kms : 500 FCFA 5 à 8kms : 750 FCFA 8 à 10kms : 1000 FCFA 10 à 15kms : 1250 FCFA A chaque pallier, le prix augmente. Néanmoins, nous ne desservirons pas certaines zones au-delà d’un certain kilométrage. </p>
      <h1 id="title4">9-Ai-je la possibilité de suivre le trajet du livreur ?</h1>
      <p>L’utilisateur Kaba n’a pas la possibilité de suivre le livreur en charge de sa commande en temps réel. Par contre, par un système de notifications, l’utilisateur est informé des différents états de sa commande. - Lorsque la commande est acceptée par le restaurant partenaire, sans doute parce que les provisions pour cuisiner le repas sont disponibles, une notification est envoyée à l’utilisateur pour l’informer que sa commande a été acceptée et est en cours de préparation. - Lorsque le restaurant fini la préparation et assigne la commande à un livreur, une nouvelle notification est envoyée à l’utilisateur, lui indiquant que sa commande est en cours de livraison, avec un délai approximatif et correct de la cuisson. Avec ce système de notifications, l’utilisateur a une idée du traitement de sa commande. </p>
      <h1 id="title4">10-Comment renseigner son adresse ?</h1>
      <p>Après avoir choisi votre menu, vous êtes priés de renseigner votre adresse (Quartier, District, Non loin de... etc.) Pour que nos livreurs vous retrouvent exactement à l’endroit où vous êtes, vous sélectionnerez votre adresse GPS (Position actuelle) et donnerez plus de détails en sus. Exemple : Agbalépédogan, non loin de la Gare routière, von à droite, en face du marché. C’est ainsi que nous avons votre position GPS, mais aussi des détails facilitant la livraison.</p>
      <h1 id="title4">11-Y’a-t-il possibilité de commander à l’adresse où on ne se trouve pas à l’instant t ? Ou peut-on commander pour quelqu’un ?</h1>
      <p>En renseignant l’adresse de livraison, vous avez la possibilité d’utiliser votre position actuelle GPS fournie par Google Maps via notre application. Vous pouvez aussi renseigner une autre adresse sur la Map sachant que vous y serez pour réceptionner votre commande, ou vous aurez un proche pour récupérer la commande, une fois le livreur arrivé à l’adresse. Donc, un utilisateur peut commander pour un autre individu (Frère, sœur, fiancée, Maman, Papa, etc.). Il n’aura qu’à parcourir la Map pour sélectionner la position GPS de ce dernier et de fournir et mot code, et numéro de téléphone ainsi que toute autre information afin que notre collaborateur livreur en charge de cette livraison puisse le joindre et faire la livraison comme il se doit. </p>
      <h1 id="title4">12-Quels sont les horaires de livraison ?</h1>
      <p>Nous effectuons des livraisons jusque tard dans la nuit. Nous nous alignons sur les heures d’ouverture et de fermeture de nos restaurants partenaires (Cf interface « Liste des restaurants »). Néanmoins, nous n’effectuons pas des livraisons au-delà de 22H30 (NB : Cette heure peut être revue à la baisse) en cas de force majeure ou pour toute autre cause. </p>
      <h1 id="title4">13-Puis-je annuler ma commande ?</h1>
      <p>La possibilité n’est pas donnée à l’utilisateur d’annuler sa commande. Avant validation de l’achat, tous les détails de la commande sont présentés à l’utilisateur ; c’est bien là qu’il pourrait donner son dernier consentement ou revenir sur sa décision en ne validant pas ladite commande.</p>
      <h1 id="title4">14-Lorsque ma commande est rejetée pour manque de provisions ou toute autre raison, suis-je remboursé ?</h1>
      <p>Oui. Lorsque la commande ne passe pas, le client ayant été débité, son compte est automatiquement crédité afin qu’il puisse repasser une autre commande illico ou ultérieurement, en fonction de sa convenance. </p>
      <h1 id="title4">15-Puis-je commander dans plusieurs restaurants en même temps ?</h1>
      <p>L’utilisateur n’a pas cette possibilité. Il peut néanmoins, commander au-delà d’un repas dans le même restaurant. En envoyant des repas dans le Shopcart (Panier), vous avez la possibilité de commander différents menus. Ainsi, vous retrouverez les repas sélectionnés dans le panier et payerez tout en même temps. C’est ce que nous appelons, un achat via le panier. </p>
      <h1 id="title4">16-Puis-je compléter ma commande ?</h1>
      <p>Selon le principe Kaba, une fois qu’une commande est passée via notre plateforme, elle n’est plus susceptible de modification. Pour compléter votre commande, vous pouvez appeler le restaurant pour la compléter et une fois le livreur en face de vous, vous compléterez aussi la somme due, si tel est bel et bien le cas. </p>
      <h1 id="title4">17-Que faire si ma commande est en retard ?</h1>
      <p>Nous faisons tout pour livrer dans un délai correct. Kaba est responsable d’un temps de livraison de 30 minutes et non d’un temps de cuisson. Cependant, en cas de retard, nous vous informerons et prendrons toutes les dispositions possibles pour que vous réceptionniez votre commande le plutôt possible. </p>
      <h1 id="title4">18-Que se passe-t-il si je ne suis pas là pour réceptionner ma commande ?</h1>
      <p>Il faut d’abord savoir que la faute incombe au client. Si, une fois, à l’adresse indiquée, le livreur ne trouve pas le client, il attendra maximum 7minutes. S’il n’arrive toujours pas à joindre le client, il reboursera chemin. Le client par la suite, pourra rappeler le livreur ou se rendre au restaurant pour récupérer sa commande. Si le client ne le fait pas, il n’aura droit à aucun remboursement car la commande sera considérée comme livrée. </p>
      <h1 id="title4">19-Est-ce qu’on peut être au courant des offres promotionnelles ?</h1>
      <p>Oui. Des notifications sont envoyées à l’utilisateur pour l’informer des offres promotionnelles soit, inhérentes à Kaba soit, inhérentes aux restaurants partenaires. </p>
      <h1 id="title4">20-Ai-je droit à des tickets ou bons de réduction ?</h1>
      <p>Kaba, programme automatiquement ses remises en fonction des paramètres pré établies. Néanmoins, des codes ou bons de réduction seront disponibles dans un futur proche. </p>
      <h1 id="title4">21-Dois-je laisser un pourboire ?</h1>
      <p>C’est à votre aise. Vous pouvez directement remettre des pourboires à nos collaborateurs. Ce sont des gratifications qu’ils garderont à 100%. Merci d’avance. </p>
      <h1 id="title4">22-Que faire en cas d’allergie ?</h1>
      <p>Vous avez la possibilité de consulter les ingrédients qui composent un repas. A base de cela, vous pourrez faire un choix. En outre, avez-vous la possibilité de choisir ou de préciser les ingrédients que vous ne désirez pas. Kaba n’est pas qu’un intermédiaire, il se soucie de votre santé. Cependant, cette fonctionnalité sera disponible dans la version 2. </p>
      <h1 id="title4">23-Puis-je avoir une idée de mes opérations sur Kaba ? </h1>
      <p>Deux interfaces ont été conçues dans ce sens. Une, dénommée ‘’Historique des commandes’’ où le client a la possibilité de consulter ses commandes, celles qu’il a passées avec tous les détails y afférents, depuis qu’il est utilisateur. Une autre interface ‘’Mon compte/historique des transactions’’ a été pensée afin que le client ou l’utilisateur ait un historique des transactions ‘’Crédit’’, lorsqu’il recharge son compte, ‘’Débit’’ lorsqu’il effectue un achat. </p>
    </div>
  );
};

export default Body;