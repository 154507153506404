import React from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  setIndex: any
  currentIndex: number
  lastIndex: number
  handleSubmit: any
  setChoice:any
  setState?:any
  state?: boolean
  loading: boolean | null
}

const PrevNext = (props:Props) => {
  const {t} = useTranslation()
  const handlePrev = () =>{
  window.scrollTo({top: window.innerWidth < 600 ? 0:120})
    if(props.currentIndex===1){
      props.setChoice('choice')
    }else{
      props.setIndex(props.currentIndex-1)
      props.setState(true)
    }
  }
  const handleNext = () =>{
    window.scrollTo({top: window.innerWidth < 600 ? 0:120})
    props.setIndex(props.currentIndex+1);
    props.setState(false);
  }
  return (
    
    <div className='prev-next'>
      <button className='button-prev' onClick={handlePrev}>{t('page-rejoindre.button.1')}</button>
      { props.currentIndex < props.lastIndex ?
        <button className="button-next"onClick={handleNext}>{t('page-rejoindre.button.2')}</button>
        :
        <button className='button-next' onClick={props.handleSubmit}>{props.loading===true ? <i className='fas fa-spinner fa-pulse'></i> : <>{t('page-rejoindre.button.3')}</>}</button>
      }
    </div>
  );
};

export default PrevNext; 