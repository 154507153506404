import React from 'react';
import Footer from '../components/global/Footer';
import Home from '../components/kaba-signup/sections/Home';

const KabaSignup = () => {
  return (
    <div className='kaba-signup'>
      <Home/>
      <Footer/>
    </div>
  );
};

export default KabaSignup;