import React from 'react';

const Loading = () => {
  return (
    <div className='loading-suspense'>
     <div className="loader"></div>
       
    </div>
  );
};

export default Loading;