import { inputTextField } from '../PartnerForm';
import SectionWrapper from '../SectionWrapper';
import { setButton, checkEmptyField, checkPhoneField, checkEmailField } from '../../../../utils/utils';
import PhoneInput from 'react-phone-number-input';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  setName: any
  name:inputTextField
  setLastName:any
  lastName:inputTextField
  setPhone: any
  phone: inputTextField
  setEmail: any
  email: inputTextField
  setAdress: any
  adress: inputTextField
  setVille: any
  ville: inputTextField
  setPays: any
  pays: inputTextField
}
const Section1 = (props:Props) => {
  const {t} = useTranslation()
  const infos:any = (t('global.global-infos',{returnObjects: true}))
  const villes:any = (t('global.villes',{returnObjects: true}))
  const pays:any = (t('global.pays',{returnObjects: true}))
  
  const IS = 'input-style'
  const   [phoneValue, setPhoneValue] = useState<any>("")
  const handleInput = (e:any, inputType:string,setState:any) => {
    if (["name","last-name", "ville", "adress", "pays"].indexOf(inputType) !== -1) {
      checkEmptyField(e,inputType,setState)
    }
    if (inputType === "phone") {
      checkPhoneField(e, setState)
    }
    if (inputType === "email") {
      checkEmailField(e,setState)
    }
  }
  useEffect(() => {
    if(
      props.adress.valide === true &&
      props.email.valide === true &&
      props.name.valide === true &&
      props.pays.valide === true &&
      props.phone.valide === true &&
      props.ville.valide === true &&
      props.lastName.valide === true
    ){
      setButton('active')
    }else{
      setButton('disable')
    }
  }, [props])
  
  return (
    <SectionWrapper
      title={t('page-rejoindre.section-livreur.titres.0')}
      sectionId={1}
      content={
        <div>
          <div className="inputs-container">
            <div className="input-left">
              <div className="name input-div">
                  <label>{infos[0]}*</label>
                  <input
                    type="text"
                    value={props.name.value}
                    onChange={(e)=>handleInput(e, 'name',props.setName)}
                    className={IS}
                  />
                  <span id="error"></span>
                  {props.name.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
                </div>
              <div className="last-name input-div">
                <label>{t('page-rejoindre.section-livreur.livreur-infos.0')}*</label>
                <input
                  type="text"
                  value={props.lastName.value}
                  onChange={(e)=>handleInput(e, 'last-name',props.setLastName)}
                  className={IS}
                />
                <span id="error"></span>
                {props.lastName.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
              </div>
              <div className="email input-div">
                <label>{infos[1]}*</label>
                <input
                  type="text"
                  className={IS}
                  value={props.email.value}
                  onChange={(e) => handleInput(e, "email",props.setEmail)}
                 
                />
                <span id="error"></span>
                {props.email.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
              </div>
             <div className="phone input-div">
                  <label>{t('page-rejoindre.section-livreur.livreur-infos.1')}*</label>
                  <PhoneInput
                    defaultCountry='TG'
                    value={phoneValue || props.phone.value}
                    onChange={(e)=>
                      {
                        setPhoneValue(e)
                        handleInput(e, 'phone',props.setPhone)
                      }
                    }
                    type="text"
                    className={IS}
                  />
                  <span id="error"></span>
                  {props.phone.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
                </div>
            </div>
            <div className="input-right">
              
              <div className="adress input-div">
                  <label>{infos[3]}*</label>
                  <input
                    type="text"
                    className={IS}
                    value={props.adress.value}
                    onChange={(e)=>{handleInput(e, 'adress',props.setAdress)}}
                  />
                  <span id="error"></span>
                  {props.adress.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
                </div>
              <div className="ville input-div">
                    <label>{infos[4]}*</label>
                    <select className= {IS}name="" onChange={(e)=>handleInput(e,'ville',props.setVille)} value={props.ville.value}>
                      {villes.map((elem:any)=>{
                          return(
                            <option key={elem} value={elem}>{elem}</option>
                          )
                        })}
                    </select>
                    {/* <input
                      type="text"
                      value={props.ville.value}
                      onChange={(e)=>handleInput(e,'ville',props.setVille)}
                      className={IS}
                    /> */}
                    <span id="error"></span>
                    {props.ville.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
              </div>
              <div className="pays input-div">
                <label>{infos[5]}*</label>
                <select className= {IS} onChange={(e)=>handleInput(e, 'pays', props.setPays)} value={props.pays.value}>
                  <option value="tg">TOGO</option>
                </select>
                {/* <input
                  type="text"
                  className={IS}
                  value={props.pays.value}
                  onChange={(e)=>handleInput(e, 'pays', props.setPays)}
                /> */}
                <span id="error"></span>
                {props.pays.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Section1;