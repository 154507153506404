import { useEffect} from 'react';
import { fixNav } from '../kaba-food/utils/fixNav';
import { NavHashLink} from 'react-router-hash-link'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  const {t} = useTranslation()
  const [kabafood , setKabaFood] = useState<any>('')
  const setActive = (arg:string) => {
    if(window.location.hash === arg){
      setLinkActive(arg)
      return ''
    }
  }
  const setLinkActive = (arg:string)=>{
     
    const link = document.querySelector(`#a-${arg}`)
    const isactive = document.querySelector(`#a-${arg}.active`)
    if(isactive ){
    }else{
      const activeLink =  document.querySelectorAll(`.navbar .active`)
      if(activeLink){
        activeLink.forEach(elem =>{
          elem.classList.remove('active')
        })
      }
      if(link){
        link.classList.add('active')}
    }
  }
  useEffect(()=>{
    const kabafood = document.querySelector('.kaba-food')
    setKabaFood(kabafood)
  },[])
  useEffect(()=>{
    const currentSection = (arg:number ) => {
      if(0<arg && arg < 3000){
        setLinkActive('home')
      }else if (arg >3000 && arg< 5200){
        setLinkActive('about2')
      }else if (arg >5200){
        setLinkActive('')
      }
    }
    if(kabafood){
        window.addEventListener("scroll",()=>{currentSection(window.scrollY)})
    } else {
      window.addEventListener('scroll',()=>{currentSection(window.scrollX)})
    }
  
},[kabafood])

  useEffect(()=>{
    fixNav('navbar')
  })
  return (
   <>
    <div className='navbar top'>
      <div className="container">
        <NavHashLink to="/#home">
        <div className="logo">
          <img className='blanc' src="assets/img/logo-blanc.svg" alt="logo" />
          <img className="rouge"src="assets/img/logo-rouge.svg" alt="logo" />
          <span id="kaba">KABA</span>
        </div>
        </NavHashLink>
        <ul>
          <li><NavHashLink id="a-home" smooth to="/#home"   className={()=>setActive('home')}>{t('global.navigation.0')}</NavHashLink></li>
          <li><NavHashLink id='a-about' to="/a-propos#top"  className={()=>setActive('about')}>{t('global.navigation.1')}</NavHashLink></li>
          <li><NavHashLink id='a-about2'smooth to="/#about2"  className={()=>setActive('about2')}>{t('global.navigation.2')}</NavHashLink></li>
          <li><NavHashLink id='a-join' to="/rejoindre-kaba#kaba-signup"  className={()=>setActive('join')}>{t('global.navigation.3')}</NavHashLink></li>
          <li><NavHashLink id='a-footer' to="/contact#top"  className={()=>setActive('footer')}>{t('global.navigation.4')}</NavHashLink></li>
        </ul>
        <span id="language">
          <img src="assets/img/france.png" alt="fr-flag" />
          <span>FR</span>
        </span>
      </div>  
    </div>
   </>
      
   
  );
};

export default NavBar;