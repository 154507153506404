import React from 'react';

interface Props{
  sectionId: number
  title: string
  content: any
}

const SectionWrapper = (props:Props) => {
  return (
    <div className={`wrapper section-${props.sectionId}`}>
      <div className={`wrapper-header section-${props.sectionId}-header`}>
        <p>{props.title}</p>
      </div>
      <div className={`wrapper-main section-${props.sectionId}-main`}>
        {props.content}
      </div>
    </div>
  );
};



export default SectionWrapper;