import React from 'react';
import { useTranslation } from 'react-i18next';

const Left = () => {
  const {t} = useTranslation()
  return (
    <>
    <div className='left-part'>
      <div className="welcome">
        <span id="title">{t('page-rejoindre.titre-principal.0')} </span>
        <span id="title2">KABA DELIVERY</span>
        <p>{t('page-rejoindre.sous-titre')} </p>
      </div>
      <div className="div">   
        <img src="assets/img/loc.svg" alt="" />
        <img src="assets/img/loc1.svg" alt="" />
        <img src="assets/img/loc2.svg" alt="" />
        <img src="assets/img/join-livreur.svg" alt="" />
      </div>
        <img className = "img"src="assets/img/points-red.svg" alt="" />
    </div>
    </>
  );
};

export default Left;