import SectionWrapper from "../SectionWrapper";
import { inputTextField } from '../PartnerForm';
import { setButton, checkEmptyField } from '../../../../utils/utils';
import { useEffect, useState } from 'react';
import Captcha from '../../../../../global/Captcha';
import { useTranslation } from 'react-i18next';

interface Props{
  setExistence: any
  existence: inputTextField
  setSell:any
  sell: inputTextField
  setWhy:any
  why: inputTextField
}

const Section3 = (props:Props) => {
  const {t} = useTranslation()
  const [captchaValide, setCaptcha] = useState(false)
  const handleInput = (e: any, inputType: string, setState:any)=>{
    if (["existence", "sell", "why"].indexOf(inputType) !== -1) {
      checkEmptyField(e,inputType,setState)
    }
  }
  useEffect(()=>{
    if(
      props.existence.valide === true &&
      props.sell.valide === true && 
      props.why.valide === true && 
      captchaValide === true
    ){
      setButton('active')
    }else{
      setButton('disable')
    }
  },[props,captchaValide])
  return (
    <SectionWrapper 
      sectionId={3}
      title={t('page-rejoindre.section-partenaire.titres.1')}
      content={
        <div>

          <div className="inputs-container">
            <div className="input-left">

            <div className="existence input-div">
              <label>{t('page-rejoindre.section-partenaire.partenaire-infos.2')} </label>
              <input 
                placeholder="Ex: 6 mois ou 3 ans"
                id="existence" className="input-style"
                value={props.existence.value}
                onChange={(e)=>handleInput(e, 'existence',props.setExistence)}
              />
              <span id="error"></span>
              {props.existence.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
              
            </div>
            </div>
            <div className="input-right">

            <div className="sell input-div">
              <label>{t('page-rejoindre.section-partenaire.partenaire-infos.3')}* ( Ex: 20 )</label>
              <input 
                type="number" 
                id="sell" 
                className="input-style" 
                value={props.sell.value}
                onChange={(e)=>handleInput(e, 'sell',props.setSell)}
              />
              <span id="error"></span>
              {props.sell.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
              
            </div>
            </div>
          </div>
          <div className="why input-div">
            <label>{t('page-rejoindre.section-partenaire.partenaire-infos.4')}</label>
            <textarea 
              value={props.why.value}
              onChange={(e)=>handleInput(e, 'why',props.setWhy)}
              placeholder=""></textarea>
            <span id="error"></span>
            {props.why.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
              <Captcha setCaptchaValide={setCaptcha}/>
          </div>
        </div>
      }
    />
  );
};

export default Section3;