import Compress from 'react-image-file-resizer'
interface Props{
  n: number
  states: any[]
  setStates: any[]
  title: string
}

const UploadFiles = (props:Props) => {

  const handleInput =  (e:any,setState:any) => {
    if (e.target.files[0] !== null) {
      
      Compress.imageFileResizer(
        e.target.files[0],
        800,
        800,
        "WEBP",
        90,
        0,
        (url:any)=>{console.log(url)
          setState({value:url,valide:true})
        },"base64"
      )
      // const reader = new FileReader()
      // reader.addEventListener('load', async() => {
      //   const res =  reader.result?.toString() || ''
      //   let resize: any
      //   if(res!==""){
      //     resize = await resizeImage(res)
      //   }else{
      //     resize = ""
      //   }
      //   console.log(resize)
      //   setState({value:resize,valide:true})
      // })
      // reader.readAsDataURL(e.target.files[0])
    }
  }
  return (
    <div className='upload-files'>
      <p>{props.title}</p>
      <div className="container">
        {Array(props.n).fill(1).map((elem,index)=>{
          return(
            <div className={`picture item-${index}`}>
             {props.states[index].value === null ?
                <div className="upload">
                  <input type="file" className='file-input' onChange={(e)=>handleInput(e,props.setStates[index])} />
                  <div className="under">
                    <div className="svg">
                      <span id="plus">+</span>
                      <span id="dowload">Téléchargez</span>
                    </div>
                  </div>
                </div>
              :
                <div className="done">
                  <div className="image">
                    <img src={props.states[index].value} alt="information about entity" />
                  </div>
                  <div className="cross" onClick={()=>props.setStates[index]({value:null, valide:false})}><span id='cross'>&#9587;</span></div>
                </div>
              }
            </div>
          )
        })}
      </div>
    </div>
  );
};


export default UploadFiles;