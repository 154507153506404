import { useContext, useEffect} from 'react';
import { AnimationContext } from '../../../App';
import { useTranslation } from 'react-i18next';

const Service = () => {
  const {value2} = useContext(AnimationContext)
  const [state, setState] = value2
  const {t} = useTranslation()

  useEffect(()=>{
    const targets:any = document.querySelectorAll("#about .elem")
    const observer = new IntersectionObserver((entries, observer) =>{
      entries.forEach((entry)=>{
        if(!entry.isIntersecting ){
          return
        }else{
          const img = entry.target.querySelector('img')
          const title  = entry.target.querySelector('.title')
          const description = entry.target.querySelector('.description')
          img?.classList.add("animation-1s")
          title?.classList.add("animation-2s")
          description?.classList.add("animation-2s")
          observer.unobserve(entry.target)
        }
      })
    },{threshold: 1}
    
    )
    
    if(targets){
      if(state === false){
        targets.forEach((elem:any)=>{
          observer.observe(elem)
          setState(true)
          })
      }else{
        targets.forEach((elem:any,index:any)=>{
          const item: HTMLElement | null = document.querySelector(`.item-${index+1}.elem`)
          const img:any = item?.querySelector('img')
          const title: any = item?.querySelector('.title')
          const description:any = item?.querySelector(".description")
          if(img)img.style.display = "block"
          if(title)title.style.display  = "block"
          if(description)description.style.display = "block"
        })
      }
    }
  },[])
  return (
    <section id="about" className='service'>
      <div className="header">
        <span>{t('page-acceuil.section-services.titre.0')}<span id="k">KABA</span></span>
      </div>
      <div className="main">
        <div className="item-1 elem">
            <div className="img"><img src="assets/img/shopping-bag.svg" alt="shopping-bag" /></div>
            <div className="title">{t('page-acceuil.section-services.sous-titres.0')}</div>
            <div className="description">{t('page-acceuil.section-services.textes.0')}</div>
        </div>
        <div className="item-2 elem">
            <div className="img"><img src="assets/img/fast.svg" alt="fast" /></div>
            <div className="title">{t('page-acceuil.section-services.sous-titres.1')}</div>
            <div className="description">{t('page-acceuil.section-services.textes.1')}</div>
        </div>
        <div className="item-3 elem">
            <div className="img"><img src="assets/img/badge.svg" alt="badge" /></div>
            <div className="title">{t('page-acceuil.section-services.sous-titres.2')}</div>
            <div className="description">{t('page-acceuil.section-services.textes.2')}</div>
        </div>
        <div className="item-4 elem">
            <div className="img"><img src="assets/img/credit-card.svg" alt="credit-card" /></div>
            <div className="title">{t('page-acceuil.section-services.sous-titres.3')}</div>
            <div className="description">{t('page-acceuil.section-services.textes.3')}</div>
        </div>
        <div className="item-5 elem">
            <div className="img"><img src="assets/img/dish.svg" alt="dish" /></div>
            <div className="title">{t('page-acceuil.section-services.sous-titres.4')}</div>
            <div className="description">{t('page-acceuil.section-services.textes.4')}</div>
        </div>
        <div className="item-6 elem">
            <div className="img"><img src="assets/img/discount.svg" alt="discount" /></div>
            <div className="title">{t('page-acceuil.section-services.sous-titres.5')}</div>
            <div className="description">{t('page-acceuil.section-services.textes.5')}</div>      
        </div>

      </div>

    </section>
  );
};

export default Service;