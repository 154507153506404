import React from 'react';

const Social = () => {
  return (
    <div className="social">
      <ul>
          <li><a href='https://www.facebook.com/kabadelivery'><img src="assets/img/Facebook.svg" alt="facebook" /></a></li>
          <li><a href='https://www.instagram.com/kaba_delivery/'><img src="assets/img/Instagram.svg" alt="instagram" /></a></li>
          <li><a href='https://www.linkedin.com/company/kaba-delivery-sarl/'><img src="assets/img/Linkedin.svg" alt="" /></a></li>
          <li><a href='https://twitter.com/kabadelivery'><img src="assets/img/Twitter.svg" alt="" /></a></li>
    </ul>  
    </div>
  );
};

export default Social;