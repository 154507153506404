import React from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  setChoice: any
}
const Home = (props:Props) => {
  const {t} = useTranslation()
  return (
    <div className='right-home'>
      <div className="container">
        <div className="logo">
          <img src="./assets/img/logo-blanc.svg" alt="logo" />
        </div>
        <p id='item-1'>{t('page-rejoindre.titre2.0')} <span id='kaba'>KABA</span></p>
        <p id="item-2">{t('global.slogan')} </p>
        <button className='button-next' onClick={()=>props.setChoice('choice')}>{t('page-rejoindre.button.0')} </button>
      </div>
    </div>
  );
};

export default Home;