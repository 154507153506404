import { inputTextField } from '../PartnerForm';
import SectionWrapper from '../SectionWrapper';
import { setButton, checkEmptyField } from '../../../../utils/utils';
import { useEffect, useState } from 'react';
import Captcha from '../../../../../global/Captcha';
import { useTranslation } from 'react-i18next';
interface Props{
  setWhy: any
  why:inputTextField
}
const Section4 = (props:Props) => {
  const {t} = useTranslation()
  const [captchaValide, setCaptcha] = useState(false)
  const handleInput = (e:any) => {
   checkEmptyField(e,"why",props.setWhy)
  }
  useEffect(()=>{
    if(props.why.value!=="" && captchaValide === true){
      setButton('active')
    }else{
      setButton('disable')
    }
  },[props,captchaValide])
  return (
    <div>
      <SectionWrapper 
        sectionId={1}
        title={t('page-rejoindre.section-livreur.titres.4')}
        content={
          <div>
            <div className="text-area input-div">
            <label>{t('page-rejoindre.section-livreur.livreur-infos.6')}*</label>
            <textarea 
              value={props.why.value}
              onChange={(e)=>handleInput(e)}
              placeholder=""></textarea>
            <span id="error"></span>
            {props.why.valide === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
            <Captcha setCaptchaValide={setCaptcha}/>
          </div>
          </div>
        }/>
    </div>
  );
};

export default Section4;