import React from 'react';
import AboutElement from '../components/global/AboutElement';
import Footer from '../components/global/Footer';
import JoinElement from '../components/global/JoinElement';
import Nav from '../components/global/Nav';
import NavBar from '../components/global/NavBar';
import NavBar2 from '../components/global/NavBar2';
import Social from '../components/global/Social';
import Join from '../components/kaba-food/sections/Join';
import { useTranslation } from 'react-i18next';

const KabaAbout = () => {
  const {t} = useTranslation()
  return (
    <div className='kaba-about'>
      <div className="header">
        <Nav/>
        <NavBar/>
        <NavBar2/>
      </div>
      <div className="main">
        <div className="banner"></div>
        <div className="item1">
          <div className="images">
            <img src="assets/img/component-4.svg" alt="svg" />
            <img src="assets/img/group-124.svg" alt="svg" />
          </div>
          <span id="title1">{t('page-apropos.section-qui-sommes-nous.titre')} </span>
          <p>
            {t('page-apropos.section-qui-sommes-nous.texte')} 
          <Social/>
          </p>
        </div>
        <div className="item2">
          <div className="images">
            <img src="assets/img/livreur2.png" alt="livreur" />
            <img src="assets/img/livreur1.png" alt="livreur" />
          </div>
          <div className="description">
            
            <p><span id="title2">KABA DELIVERY  </span>{t('page-apropos.section-qui-sommes-nous.texte2.1')} </p>
          </div>
        </div>
        <div className="item3">
          <div className="part1">
            <span id="title3">{t('page-apropos.section-accomplissement.titre')} </span>
            <p>{t('page-apropos.section-accomplissement.texte')}</p>
          </div>
          <div className="part2">
            <img src="assets/img/vector-num.svg" alt="vector" />
            <div className="left">
               <div className="info1 c">
              <span id='title4'>{t('page-apropos.section-accomplissement.livraison.0')}</span>
              <p>{t('page-apropos.section-accomplissement.livraison.1')}</p>
            </div>
            <div className="info2 c">
              <span id='title4'>{t('page-apropos.section-accomplissement.partenaires.0')}</span>
              <p>{t('page-apropos.section-accomplissement.partenaires.1')}</p>
            </div>
            <div className="info3 c">
              <span id='title4'>{t('page-apropos.section-accomplissement.temps-livraison.0')}</span>
              <p>{t('page-apropos.section-accomplissement.temps-livraison.1')}</p>
            </div>
            </div>
            <div className="right">
              <div className="info4 c">
              <span id='title4'>{t('page-apropos.section-accomplissement.livreur.0')}</span>
              <p>{t('page-apropos.section-accomplissement.livreur.1')}</p>
            </div>
            <div className="info5 c" >
              <span id='title4'>{t('page-apropos.section-accomplissement.transaction.0')}</span>
              <p>{t('page-apropos.section-accomplissement.transaction.1')}</p>
            </div>
            <div className="info6 c">
              <span id="title4" className='star' style={{color: "#ffbf00"}}>&#9733; &#9733; &#9733; &#9733;</span>
              <p>PlayStore</p>
              <span id="title4" className='star' style={{color: "#ffbf00"}}>&#9733; &#9733; &#9733;</span>
              <p>AppStore</p>
            </div>
            </div>
           
            
          </div>
        </div>
        <div className="item4 c2">
          <img src="assets/img/points-oranges.svg" alt='vector'/>
          <img src="assets/img/points-oranges.svg" alt='vector'/>
          <p>
            <span id='title3' className='m'>{t('page-apropos.section-equipe.vision.0')} </span>
            {t('page-apropos.section-equipe.vision.1')}
          </p>
          <p>
            <span id="title3" className='m'>{t('page-apropos.section-equipe.equipe.0')}</span>
            {t('page-apropos.section-equipe.equipe.1')}
          </p>
        </div>
        <div className="banner2"></div>
        <div className="item5 c2">
          <p>
            <span id="title3" className='m'>{t('page-apropos.section-equipe.valeurs.0')}</span>
            {t('page-apropos.section-equipe.valeurs.1')}
          </p>
          <p>
            <span id='title3' className='m'>{t('page-apropos.section-equipe.tech.0')}</span>
            {t('page-apropos.section-equipe.tech.1')}
          </p>
        </div>
        <AboutElement/>
        <Join/>
      </div>
      <Footer/>
    </div>
  );
};

export default KabaAbout;