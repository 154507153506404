import SectionWrapper from "../SectionWrapper";
// import { liste } from './datas';
import { useEffect, useState } from 'react';
import { setButton } from '../../../../utils/utils';
import { inputTextField } from '../PartnerForm';
import { useTranslation } from 'react-i18next';



interface Props{
  setWhoIam:any
  whoIam: inputTextField
  setState: any
  state: any
}

const Section1 = (props:Props) => {
  const {t} = useTranslation()
  const [toogle, setToogle] = useState<boolean>(false)
  const [liste, setListe] = useState<any>(t('page-rejoindre.section-partenaire.partenaire-type',{returnObjects: true})
  )
  const handleClick = (e:any) => {
    const buttonNext = document.querySelector(".button-next")
    buttonNext?.classList.remove('disabled')
    buttonNext?.removeAttribute("disabled")

    // const activeLi = e.target.parentNode.querySelector('.active')
    // if(activeLi){
    //   activeLi.classList.remove('active')
    // }
    // e.target.classList.add("active")
    if (e.target.outerText === "Autres"){
     }else{
        document.querySelector('.F-active')?.classList.remove('F-active')
        setToogle(false)
        props.setWhoIam({value: e.target.outerText, valide: false})
      }
    }
  
  useEffect(()=>{
    setButton("active")
  },[])
  return (
    <SectionWrapper 
      sectionId={1}
      title={"Je suis un(e) :"} 
      content={
        <div>
          <ul>
            {liste && liste.map((item:any)=>{
            return(
              <li className={`${item===props.whoIam.value?"active":""}`}onClick={(e)=>handleClick(e)} key={item}>{item}</li>
            )
          })}
            <li className={`${liste.indexOf(props.whoIam.value)===-1?"F-active":""}`} onClick={(e:any)=>{
               e.target.classList.add("F-active")
               setToogle(true)
            }}>Autres</li>
          </ul>
          {(toogle ===true || liste.indexOf(props.whoIam.value)===-1)  && 
            <input 
              className="input-style"
              type="text" 
              autoFocus
              onBlur={()=>{
              }}
              value={props.whoIam.value}
              onChange={(e)=>{
                const buttonNext = document.querySelector(".button-next")
                if(e.target.value===""){
                  buttonNext?.classList.add('disabled')
                  buttonNext?.setAttribute("disabled","true")
                }else{
                  buttonNext?.classList.remove('disabled')
                  buttonNext?.removeAttribute("disabled")
                }
                props.setWhoIam({value: e.target.value, valide: false})
                
                }}/>}
        </div>
      }
    />
  );
};

export default Section1;