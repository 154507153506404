import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
  isOpen: boolean
  setIsOpen?: any
  status:string
  home?:boolean
}

const ModalComponent = (props:Props) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const handleClick = ()=>{
    props.setIsOpen(false)
    if(props.status==="success" || props.status==="contact"){
        navigate('/#home',{state:{name:'formulaire'}});

    }
  }
  return (
    <div>
      <Modal 
          isOpen={props.isOpen}>
            {(props.status==="success" || props.status === "contact" ) ? 
              <img src="assets/img/success.png" alt="logo" />
              :
              <img src="assets/img/error.png" alt="logo" />

            }
            {props.status==="success" ? 
              <p>{t('global.popup-messages.0')}</p>
              :
                (props.status === "contact") ? 
                <p style={{textAlign: "center"}}>{t('global.popup-messages.1')}</p>
                :
                <p style={{textAlign: "center"}}>{t('global.popup-messages.2')}</p>
            }
              <div className="button">
                <button onClick={handleClick}>Ok</button>
              </div>
      </Modal>
    </div>
  );
};

export default ModalComponent;