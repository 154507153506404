import React, {Suspense, useState } from 'react';
import KabaFood from './page/KabaFood';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import KabaSignup from './page/KabaSignup';
import KabaPC from './page/KabaPC';
import KabaCGU from './page/KabaCGU';
import KabaML from './page/KabaML';
import KabaFAQ from './page/KabaFAQ';
import KabaContact from './page/KabaContact';
import KabaAbout from './page/KabaAbout';
import ToHome from './components/global/ToHome';
import i18n from './language/i18n';
import LocaleContext from './language/LocaleContext';
import Loading from './components/global/Loading';
import ReactGA from 'react-ga';
import { useEffect }from 'react';
  const TRACKING_ID = "G-ERR3E06FDY"; // OUR_TRACKING_ID
  
  
  export const AnimationContext = React.createContext<any>(null)
  function App() {
    const [locale, setLocale] = useState(i18n.language);
    const [anim, setAnim] = useState<boolean>(false)
    const [anim2, setAnim2] = useState<boolean>(false)
    const [anim3, setAnim3] = useState<boolean>(false)
    const [anim4, setAnim4] = useState<boolean>(false)
    const [anim5, setAnim5] = useState<boolean>(false)

    useEffect(()=>{
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  return (
    <div className="App">
      <LocaleContext.Provider value={{locale, setLocale}}>
      <Suspense fallback={<Loading/>}>
      <AnimationContext.Provider value={{value1:[anim, setAnim],value2:[anim2, setAnim2],value3: [anim3, setAnim3],value4:[anim4, setAnim4], value5:[anim5, setAnim5]}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <KabaFood/>}/>
          <Route path="*" element={ 
            <ToHome/>
            }/>
          <Route path="/rejoindre-kaba" element={<KabaSignup/>}/>
          <Route path='/politique-de-confidentialite' element={<KabaPC/>}/>
          <Route path='/conditions-generales-utilisations' element={<KabaCGU/>}/>
          <Route path='/mentions-legales' element={<KabaML/>}/>
          <Route path='/faq' element={<KabaFAQ/>}/>
          <Route path='/contact' element={<KabaContact/>} />
          <Route path='/a-propos' element={<KabaAbout/>}/>
        </Routes>
      </BrowserRouter>
      </AnimationContext.Provider>
      </Suspense>
      </LocaleContext.Provider>
    </div>
    
  );
}

export default App;
