import ReCAPTCHA from 'react-google-recaptcha'
import { useState} from 'react';

interface Props {
  setCaptchaValide:any
}

const Captcha = (props:Props) => {
  // const  [loading, setLoading] = useState(true)
  
  function onChange(value:any) {
    if(value){
      props.setCaptchaValide(true)
    }
  }

  return (
    <div className="captcha">
      {/* {loading ===  true && <i style={{color: "white"}}className='fas fa-spinner fa-pulse'></i>} */}
        
        <ReCAPTCHA
          sitekey="6Lf97b8fAAAAAGNwmMjv09WWvxWoxx6hZsnmvLd-"
          onChange={onChange}
        />
    
    </div>
  );
};

export default Captcha