import React from 'react';

const Body = () => {
  return (
    <div>
      <p>Merci de lire attentivement les Conditions Générales d’Utilisation et de Vente ci-dessous, avant de passer commande de quelque produit ou service sur l’application KABA. En passant des commandes sur ladite application, quel que soit le moyen utilisé, vous acceptez de vous soumettre à ces Conditions Générales d’utilisation et de Vente (CGUV)</p>
      <h1 id="title">Section 1 : Présentation de KABA
      </h1>
      <p>Kaba est une application qui permet la commande et la livraison de menus restaurants, mise au point par KABA DELIVERY SRAL. L’application a été conçue dans le dessein de permettre à toute personne de pouvoir commander des menus restaurants dans des établissements gastronomiques partenaires, et de se les faire livrer à toute adresse, par des livreurs professionnels.</p>
      <h1 id="title">Section 2 : Définition des termes</h1>

      <p><span>2.1. </span>“Accord” se réfère à ces Conditions Générales d’utilisation et de Vente (CGUV), la politique de confidentialité, les informations de paiement, et tout bien qui vous est livré ;
      </p>
      <p><span>2.2. </span>Par “Politique de Confidentialité”, nous entendons la politique accessible via notre application, qui détaille la façon dont nous collectons et conservons vos informations personnelles ;
      </p>
      <p><span>2.3. </span>“vous”, “votre”, et “vos” se réfèrent à vous, la personne ayant téléchargé l’application et passant commande pour un bien ou un service que nous y proposons. L’accès à Kaba par un quelconque autre moyen vous lie également à ces Conditions Générales de Vente ;
      </p>
      <p><span>2.4. </span>“nous”, “notre”, “nos” et Kaba " se réfèrent à l’entreprise ;
      </p>
      <p><span>2.5. </span>“Produits” et "biens" se réfèrent à tous les produits que nous proposons à la vente sur notre application soit en fonction d’une durée déterminée, ou indéterminée selon la disponibilité desdits biens au niveau de nos restaurants partenaires ;
      </p>
      <p><span>2.6. </span>“Service” ou “Services” se réfèrent à tous les services que nous proposons ou qui sont suggérées via notre application ;
      </p>
      <p><span>2.7. </span>“Restaurant partenaire” est un tiers, qui a accepté de coopérer avec l’entreprise dans le but de préparer des menus restaurants aux clients, utilisateurs de l’application
      </p>
      <p><span>2.8. </span>"Livraison de repas" se réfère à des biens périssables (menus de nos restaurants partenaires) et desquels, ils assument l’entière responsabilité en ce qui concerne leur processus de préparation ;
      </p>
      <p><span>2.9. </span>“Plateforme” se réfère à notre application mobile ou quelque autre plateforme que nous choisirons pour vous proposer des biens et des services.</p>
      <h1 id="title">Section 3 : Commander
      </h1>
      <p><span>3.1. </span>Tout contrat pour la commande de repas sur notre application se fait entre vous et le Restaurant partenaire ; et tout contact se fait entre vous et KABA, qui est un intermédiaire entre le restaurant partenaire et le client, pour les intérêts des deux parties. Vous acceptez, après le téléchargement de notre application, créer un compte et de nous apporter les informations utiles pour jouir de nos services. Vous acceptez les fournir à chaque fois que vous manifesterez votre envie de passer une commande. Lorsque vous passez une commande, assurez-vous que ces informations sont exactes et à jour.
      </p>
      <p><span>3.2. </span>Si vous choisissez de payer avec l’un des moyens de paiement proposés sur l’application (Flooz, T-Money etc.), vous garantissez que vous en êtes le titulaire légal et propriétaire des fonds qui y sont liés. Vous garantissez également que vous avez les fonds nécessaires pour effectuer le paiement. Vous pouvez contracter les Services seulement en tant qu’acteur principal.
      </p>
      <p><span>3.3. </span>Lorsque vous passez une commande sur notre application, nous vous demandons de fournir un numéro de téléphone, une adresse et un mot de passe. Vous devez vous assurer de conserver ces données, personnelles, et de ne point les communiquer à un tiers.
      </p>
      <p><span>3.4. </span>Toute commande passée sur notre plateforme est sujette à la disponibilité, la capacité de livraison, et à son acceptation par le restaurant partenaire et KABA. Une fois la commande passée en ligne, une notification vous est envoyée vous informant de la validation ou de l’acceptation ou non de votre commande. D’autres notifications vous seront envoyées au fur et à mesure pour vous informer du traitement de votre commande.
      </p>
      <p><span>3.5. </span>Avant que vous ne validiez votre commande, les détails (Mini facture) de cette dernière, vous sont proposés afin d’en assurer l’exactitude. Les frais de livraison dont vous êtes redevables vous sont aussi présentés en fonction de votre distance, avant chaque validation et paiement.</p>
      <h1 id="title">Section 4 : Prix & Paiements</h1>
      <p><span>4.1. </span>Tout contrat pour l’achat de repas via notre application se fait entre vous et le Restaurant partenaire ; en ce qui concerne la livraison et services connexes offerts par notre plateforme, le contrat se fait entre vous et KABA. Vous acceptez de nous fournir des informations exactes, et de vous assurer de leur exactitude au moment de passer une commande. Vous garantissez que vous êtes le titulaire légal et propriétaire des fonds qui y sont liés. Vous garantissez également que vous avez les fonds nécessaires pour effectuer le paiement.
      </p>
      <p><span>4.2. </span>Tous les prix des menus restaurants affichés sur la plateforme, sont exacts au moment de leur publication et sont en rapport avec la politique des restaurants partenaires. Ces prix peuvent subir des modifications selon le bon vouloir de nos partenaires, selon qu’il y’ait des réductions, promotions ou tout fait quelconque entrainant la modification des prix. Les prix comprennent les taxes liées si possible ainsi que les frais de livraison dans les détails de chaque commande passée. Le restaurant partenaire est responsable de la modification de sa carte menu et KABA a la responsabilité de les faire figurer sur la plateforme avec leurs prix normaux ou leurs prix de promotion. Nous nous réservons également le droit de modifier des produits ou services disponibles à la vente sur l’application, et d’arrêter de référencer des restaurants, produits ou services.
      </p>
      <p><span>4.3. </span>Tous les prix affichés sur la plateforme par les Restaurants partenaires sont ceux que ces derniers ou le tiers fournissent au moment de la mise en ligne. Nous portons une grande attention à leur mise à jour.
      </p>
      <p><span>4.4. </span> Tous les coûts de livraison de Kaba sont présentés au client en fonction de sa distance. Les frais de livraison peuvent subir des modifications de baisse ou d’augmentation. Nous nous réservons le droit exclusif de ces modifications.
      </p>
      <p><span>4.5. </span>Le prix total pour la livraison de repas, et les produits et services commandés, vous seront affichés au moment de passer votre commande. Le paiement doit être effectué dans son intégralité pour tous les produits et services fournis. Le paiement se fait par deux principaux moyens : Flooz ou T-Money et aussi par espèces avec appoint c’est-à-dire que le client doit avoir la somme exacte de sa commande.
      </p>
      <p><span>4.6. </span>L’utilisateur Kaba, doit avoir son compte rechargé avant de passer quelque commande que ce soit. Il est donc invité à recharger son compte T-Money ou Flooz, selon que son numéro d’utilisateur soit de Togocel ou de Moov. Suite à la recharge, la possibilité est donnée à l’utilisateur de passer une commande, si le rechargement de compte effectué est suffisant pour le prix total de la facture, fais de livraison inclus.
      </p>
      <p><span>4.7. </span>Vous êtes immédiatement débité lorsque la vérification de votre solde suffisant est faite et lorsque vous validez votre commande. Si votre commande est rejetée par le restaurant, le montant qui avait été préalablement débité, vous sera remboursé automatiquement.
      </p>
      <p><span>4.8. </span>Kaba Delivery, assume l’entière part de responsabilité qui lui revient sur les transactions que vous faites. La société garantit aux utilisateurs la sécurité des fonds qui sont retransmis sur sa plateforme, car utilisant des systèmes de paiement dématérialisé des deux principaux acteurs de la téléphonie Mobile au Togo, qui ont un système de sécurisation des transactions financières qui transitent vers leurs plateformes de mobile money (T-Money et Flooz, par le service PayGate en ce qui nous concerne).</p>
      <h1 id="title">Section 5 : Livraison</h1>
      <p><span>5.1. </span>La livraison est faite par les livreurs de KABA. Cette dernière est responsable de tous ses services de livraison des menus restaurants et tout fait ou acte y afférent directement ou indirectement, si sa responsabilité est remise en cause dans ce cas. Aucune durée minimum, maximum, ni fixe de livraison n’est garantie au client si ce n’est une durée normale, acceptable et surtout raisonnable de 30 minutes. Les produits sont livrés à l’adresse saisie lors du passage de la commande. L’utilisateur a la possibilité de créer jusqu’à cinq (05) adresses. KABA est dans l’obligation de vous informer d’une quelconque impossibilité due à un cas de force majeure survenu lors de la livraison.
      </p>
      <p><span>5.2. </span>Tous les risques liés aux produits et à la livraison de repas vous seront transférés au moment de la livraison.
      </p>
      <p><span>5.3. </span>Les utilisateurs sont tenus d’être toujours disponible tant physiquement, que par appel téléphonique, pour réceptionner leur commande. Si, une fois sur place ; nos livreurs n’arrivent pas à joindre un client ou le rencontrer, et qu’ils rebroussent chemin par conséquent après un délai de 7 minutes, les frais de livraison qui ont été débités ainsi que le prix de la commande, ne sont pas remboursables. Le client est alors appelé à attendre la fin de la course du livreur (si ce n’est pas sa dernière livraison) pour que celui-ci revienne à lui. Si une fausse adresse est fournie par le client induisant en erreur le livreur, des frais de livraison seront débités au client en fonction de la distance parcourue.
      </p>
      <p><span>5.4. </span>A chaque commande, un mot code servant de mot de passe est généré par notre système. A la livraison, ce mot code est demandé au client pour des question de sécurité et de protection. Vous acceptez à travers ces présentes, de fournir le mot code afin de réceptionner votre commande. En effet, sans ce mot code, votre commande ne saurait vous être remise.
      </p>
      <p><span>5.5. </span>Vous acceptez au travers de cet accord, l’impossibilité de livraison dans certains quartiers pour des raisons temporaires de distance ou difficiles d’accès. Si c’est le cas, vous en serez toujours informés directement sur l’application. Un autre restaurant dans le rayon accessible, vous sera proposé dans le but que vous passiez une commande similaire.</p>
      <h1 id="title">Section 6 : Sites liés & autres services</h1>
      <p>Sur notre site internet peuvent figurer des liens vers des sites tiers qui peuvent vous intéresser selon nous. Nous ne représentons pas la qualité des biens et services proposés par ces sites tiers et n’avons aucun contrôle que le contenu ou la disponibilité de leurs sites. Nous ne pouvons pas accepter la responsabilité du contenu de ces liens ou des produits et/ou servies qu’ils vous proposent, quand bien même nous sommes responsables des services de pub ou de visibilité que nous leur offrons</p>
      <h1 id="title">Section 7 : Complaints</h1>
      <p>La proximité avec nos utilisateurs, est une de nos valeurs. Nous prenons les réclamations très au sérieux et plaçons les clients au centre de notre activité. Nous faisons tout notre possible pour vous répondre dans les 5 jours ouvrés. Merci d’envoyer vos réclamations à contact@kaba-delivery.com ou en contactant directement notre service clientèle. Par ailleurs, les réclamations dont il s’agira, doivent être liées à nos services de livraison et doivent les concerner directement. Toute réclamation issue d’une faute entraînée par un fait de restaurant partenaire entraînant votre mécontentement, n’engage pas personnellement kaba. Cette réclamation sera prise en charge mais redirigée vers le restaurant car le concernant directement.</p>
      <h1 id="title">Section 8 : Limitation de responsabilité</h1>
      <p><span>8.1. </span>Nous ne sommes pas responsables des informations incorrectes, fausses et frauduleuses fournies pas un utilisateur et qui posent à lui-même ou à autrui des préjudices.
      </p>
      <p><span>8.2. </span>Nous ne sommes pas responsables des actions frauduleuses commises par certains utilisateurs mal intentionnés
      </p>
      <p><span>8.3. </span> Nous reconnaissons notre responsabilité de prendre toutes les mesures nécessaires pour empêcher la fraude en ligne et s’assurer que les données recueillies vous concernant, sont stockées de façon sécurisée.
      </p>
      <p><span>8.4. </span>KABA prend le soin de remédier à des erreurs qui pourraient surgir à tout moment et s’en excuse d’avance. Nous ne pouvons pas garantir que l’utilisation de nos plateformes sera faite à bon escient ou de manière totalement sûre. Nous ferons de notre mieux pour corriger de telles erreurs rapidement et efficacement.
      </p>
      <p><span>8.5. </span>En acceptant ces termes d’utilisation, vous acceptez de nous décharger de toute responsabilité relevant de votre utilisation des informations provenant d’un parti tiers ou de vos informations personnelles, de votre utilisation de nourriture ou boisson d’un Restaurant partenaire.
      </p>
      <p><span>8.6. </span>KABA n’est pas responsable de tout préjudice (intoxication, maladie, indigestion etc.) causé via une mauvaise préparation d’un restaurant. Pour éviter cela, Kaba met à la disposition de ses utilisateurs des emballages personnalisés et biodégradables. Ces contenants sont scellés jusqu’à la rencontre du client, pour garantir à ce dernier qu’aucune tentative d’ouverture d’emballage pour des raisons diverses a été faite.
      </p>
      <p><span>8.7. </span>Dans l’éventualité où Kaba aurait raison de croire qu’il existerait des comportements inadéquats ou une quelconque suspicion d’instance de fraude, nous nous réservons le droit de bloquer l’utilisateur (ou client).</p>
      <h1 id="title">Section 9 : Généralités</h1>
      <p><span>9.1. </span>Tous les prix sont en Francs CFA.</p>
      <p><span>9.2. </span>Nous pouvons sous-traiter toutes les parties des produits ou services que nous vous proposons et nous pouvons assigner une partie de nos droits cités dans ces CGUV sans votre consentement ou sans vous en notifier par avance.
      </p>
      <p><span>9.3. </span>Nous nous réservons le droit de modifier les CGUV à tout moment.
      </p>
      <p><span>9.4. </span>Le paiement doit être effectué au moment de la commande par les moyens acceptés par KABA. Le paiement par espèces est aussi accepté sur la plateforme à condition que le Client fasse l’appoint.
      </p>
      <p><span>9.5. </span>Ne pas utiliser ou lancer de système ou programme automatique sur notre application ou ses fonctionnalités de commande en ligne.
      </p>
      <p><span>9.6. </span>Ne pas collecter des données personnelles en continu sur l’application, site internet, ne pas utiliser le système de communication fourni par le site pour toute demande à caractère commercial, ne pas contacter les restaurants partenaires pour des services particuliers en dehors de la politique de livraison sollicitée. Ne pas pirater le système de l’application pour risque d’encourir des peines légales ou financières extrêmement lourdes.
      </p>
      <p><span>9.7. </span>Si un terme ou une condition de notre accord est déterminée invalide, illégale ou inapplicable, les parties s’accordent sur le fait que ce terme ou cette condition doit être nulle au regard de la loi, sauf cas de modification.</p>
      <h1 id="title">Section 10: Litiges</h1>
      <p><span>10.1 </span>Ces Conditions Générales d’utilisation et de Vente et notre accord doivent être gouvernées et interprétées selon les lois togolaises et/ou sous-régionales. Les Parties sont soumises à la juridiction exclusive des tribunaux togolais.
      </p>
      <p><span>10.2 </span>Tout retard ou incapacité à faire respecter les termes du présent contrat ne constitue pas une renonciation de notre part à nos droits sauf dans le cas où notre renonciation est confirmée par écrit.
      </p>
      <p><span>10.3 </span>Ces Conditions Générales d’utilisation et de Vente et un contrat, aussi toutes les obligations non-contractuelles qui y sont liées ou en découlent doivent être gouvernées et interprétées en accord avec les lois togolaises et ou si nécessaires par les lois sous-régionales. Les Parties s’accordent se soumettre à la non exclusive juridiction des tribunaux togolais. Les Parties s’engagent lors d’un litige, user des voies de l’amiable et de l’arbitrage avant toute saisine d’un tribunal compétent. La correspondance et contacts entre les utilisateurs ou clients se feront en français ou en anglais.</p>

  </div>
  );
};

export default Body;