import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useEffect, useState, useContext } from 'react';
import { AnimationContext } from '../../App';
import { useTranslation } from 'react-i18next';

const AboutElement = () => {
  const [kabafood , setKabaFood] = useState<any>('')
  const {value4} = React.useContext(AnimationContext)
  const {t} = useTranslation()
  const [state, setState] = value4

  useEffect(()=>{
    const kabafood = document.querySelector('.kaba-food')
    setKabaFood(kabafood)
  },[])
  useEffect(()=>{
    const targets: any = document.querySelectorAll(".global.element-2 .item")
    if(kabafood){
      const observer = new IntersectionObserver((entries,observer)=>{
      entries.forEach((entry)=>{
        if(!entry.isIntersecting){
          return
        }else{
          entry.target.classList.add('animation-1')
          observer.unobserve(entry.target)
        }
      })
      },{threshold: 1})
      if(targets){
          if(state === false){
          targets.forEach((elem:Element) => {
            observer.observe(elem)
            setState(true)
          });
        }else{
          targets.forEach((element:HTMLElement) => {
              element.style.opacity = "1"
          });
        }
      }
    }
  },[kabafood,setState])
  return (
    <div className="global element-2">
            <span id="mod-2">{t('page-acceuil.section-simplifier.titre.0.0')}<span id="primary" style={{display: "inline"}}>{t('page-acceuil.section-simplifier.titre.0.1')}</span><br/>{t('page-acceuil.section-simplifier.titre.1')}</span>
            <p>{t('page-acceuil.section-simplifier.titre-texte')}</p>
            <div className="end">
            
              <div className="item">
                <div className="img"><img src="assets/img/freelance.svg" alt="freelance" /></div>
                <div className="title">{t('page-acceuil.section-simplifier.sous-titres.0')}</div>
                <div className="description">{t('page-acceuil.section-simplifier.sous-titres-textes.0')}</div>
              </div>
              <div className="item">
                <div className="img"><img src="assets/img/light-bulb.svg" alt="light-bulb" /></div>
                <div className="title">{t('page-acceuil.section-simplifier.sous-titres.1')}</div>
                <div className="description">{t('page-acceuil.section-simplifier.sous-titres-textes.1')}</div>
              </div>
              <div className="item">
                <div className="img"><img src="assets/img/surface1.svg" alt="surface" /></div>
                <div className="title">{t('page-acceuil.section-simplifier.sous-titres.2')}</div>
                <div className="description">{t('page-acceuil.section-simplifier.sous-titres-textes.2')}</div>
              </div>
            </div>
            <NavHashLink to="/a-propos#top"><button>{t('page-acceuil.section-simplifier.button')}</button></NavHashLink>
          </div>
  );
};

export default AboutElement;