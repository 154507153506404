import { useEffect, useState } from "react"
import { fixNav } from "../kaba-food/utils/fixNav"
import { NavHashLink } from 'react-router-hash-link';

interface Props{
  fixed?: boolean
}
const NavBar2 = (props:Props) => {
  const [nav, setNav] = useState< HTMLElement | null>(
  )
  const [toggle, setToggle] = useState<boolean>(false)

  const handleClick = ()=>{
    if(toggle === false && nav){
        nav.style.transform  = "translateY(600px)"
      setToggle(!toggle)}
    else{
      if(nav){
        nav.style.transform='translateY(-600px)'
        setToggle(!toggle)
      }
    //   if(nav.style.display === "none") {
    //     nav.style.display="block"
    //   }else{ if(nav)nav.style.display="block"
    // }
    }
  }

  useEffect(()=>{
    if(props.fixed!==false){

      fixNav('navbar2')
    }
    const nav:HTMLElement | null = document.querySelector(".nav-resp")
    if(nav)setNav(nav)
  },[nav,props])
  return(
    <div className="navbar2">
      <div className="container">
        <div className="right" onClick={handleClick}>{toggle === false ? <img  src="assets/img/nav.svg" alt="nav" /> : <span id="cross">&#9587;</span>}</div>
          <div className="middle">
          <NavHashLink to="/#home">
            <img src="assets/img/logo-blanc.svg" alt="logo" />
          </NavHashLink>
          <NavHashLink to="/#home">
            <span>KABA</span>
          </NavHashLink>
          </div>
          </div>
    </div>
  )
}

export default NavBar2