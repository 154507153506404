import Nav from "./Nav";
import { useState } from "react";
import {
  initialValue,
  initialValueFile,
  inputFileField,
  inputTextField,
} from "./PartnerForm";
import Section1 from "./shipper-form/Section1";
import Section2 from "./shipper-form/Section2";
import Section3 from "./shipper-form/Section3";
import Section4 from "./shipper-form/Section4";
import { motion } from "framer-motion";
import PrevNext from "./PrevNext";
import ModalComponent from "../../../../global/Modal";
import { useMutation } from "@apollo/client";
import { createApplication } from "../../../../graphql/Mutation";
import { useNavigate} from 'react-router-dom';
import {useEffect } from 'react';

export interface description {
  have: boolean;
  brand: [string, boolean];
  smartBrand: [string, boolean];
  timePossession: [string, boolean];
  plaque: [string, boolean];
}
interface Props {
  setChoice: any;
}

const ShipperForm = (props: Props) => {
  const initialDescription: description = {
    have: true,
    brand: ["", false],
    smartBrand: ["", false],
    timePossession: ["", false],
    plaque: ["", false],
  };
  const [index, setIndex] = useState<number>(1);
  const [state, setState] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  
  const [mutationFunc, {loading}] =
    useMutation(createApplication);
  const navigate = useNavigate()

  const [name, setName] = useState<inputTextField>(initialValue);
  const [lastName, setLastName] = useState<inputTextField>(initialValue);
  const [phone, setPhone] = useState<inputTextField>(initialValue);
  const [email, setEmail] = useState<inputTextField>(initialValue);
  const [adress, setAdress] = useState<inputTextField>(initialValue);
  const [ville, setVille] = useState<inputTextField>({value:"Lomé",valide:true});
  const [pays, setPays] = useState<inputTextField>({value:"tg",valide:true});

  const [motoDescription, setMotoDescription] =
    useState<description>(initialDescription);
  const [motoImg, setMotoImg] = useState<inputFileField>(initialValueFile);

  const [phoneDescription, setPhoneDescription] =
    useState<description>(initialDescription);
  const [workOptions, setWorkOptions] = useState<string>("all");

  const [why, setWhy] = useState<inputTextField>(initialValue);

  const handleSubmit = () => {
    const input = {
      type: "SHIPPER",
      name: name.value,
      surname: lastName.value,
      phone_number: phone.value,
      email: email.value,
      city: ville.value,
      district: adress.value,
      country: pays.value,
      have_motorbike: motoDescription.have === false ? "no" : "yes",
      motorbike_brand: motoDescription.brand[0],
      motorbike_plate_number: motoDescription.plaque[0],
      motorbike_picture: (!motoImg.value) ? "": motoImg.value,
      have_phone: phoneDescription.have === false ? "no" : "yes",
      phone_brand: phoneDescription.smartBrand[0],
      phone_lifetime: phoneDescription.timePossession[0],
      working_mode: workOptions,
      purpose: why.value,
    };
    mutationFunc({
      variables: {
        input,
      },
    })
      .then(() => {
        setStatus("success");
        setIsOpen(true);
        // navigate('/#home',{state:{name:'formulaire'}});
      })
      .catch((err) => {
        setStatus("error");
        setIsOpen(true);
      });
  };
  useEffect(()=>{
  },[ville,pays])
  return (
    <div className="shipper-form">
      <Nav n={5} currentIndex={index} />
      <ModalComponent status={status} isOpen={isOpen} setIsOpen={setIsOpen} />
      <form>
        {index === 1 && (
          <motion.div
            initial={{ x: state === false ? "20vw" :"-10vw", opacity :0} }
          animate={{ x: 0 ,opacity:1 }}
            exit={{ y: "-300px", opacity: 0 }}
            transition={{ stiffness: 100, duration: 0.7, ease: "easeOut" }}
          >
            <Section1
              setName={setName}
              name={name}
              setLastName={setLastName}
              lastName={lastName}
              setPhone={setPhone}
              phone={phone}
              setEmail={setEmail}
              email={email}
              setAdress={setAdress}
              adress={adress}
              setVille={setVille}
              ville={ville}
              setPays={setPays}
              pays={pays}
            />
          </motion.div>
        )}

        {index === 2 && (
          <motion.div
            initial={{ x: state === false ? "20vw" :"-10vw", opacity :0} }
          animate={{ x: 0 ,opacity:1 }}
            transition={{ stiffness: 100, duration: 0.7, ease: "easeOut" }}
            exit={{ y: "-300px", opacity: 0 }}
          >
            <Section2
              setMotoDescription={setMotoDescription}
              motoDescription={motoDescription}
              setMotoImg={setMotoImg}
              motoImg={motoImg}
            />
          </motion.div>
        )}
        {index === 3 && (
          <motion.div
            initial={{ x: state === false ? "20vw" :"-10vw", opacity :0} }
          animate={{ x: 0 ,opacity:1 }}
            transition={{ stiffness: 100, duration: 0.7, ease: "easeOut" }}
            exit={{ y: "-300px", opacity: 0 }}
          >
            <Section3
              setPhoneDescription={setPhoneDescription}
              phoneDescription={phoneDescription}
              setWorkOptions={setWorkOptions}
              workOptions={workOptions}
            />
          </motion.div>
        )}
        {index === 4 && (
          <motion.div
            initial={{ x: state === false ? "20vw" :"-10vw", opacity :0} }
          animate={{ x: 0 ,opacity:1 }}
            exit={{ y: "-300px" }}
            transition={{ stiffness: 100, duration: 0.7, ease: "easeOut" }}
          >
            <Section4 setWhy={setWhy} why={why} />
          </motion.div>
        )}
      </form>
      <PrevNext
        setIndex={setIndex}
        currentIndex={index}
        lastIndex={4}
        setState={setState}
        state={state}
        handleSubmit={handleSubmit}
        setChoice={props.setChoice}
        loading={loading}
      />
    </div>
  );
};

export default ShipperForm;
