import { motion } from 'framer-motion';
import React from 'react';

interface Props{
  n: number
  currentIndex: number
}

const Nav = (props:Props) => {
  const lastindex = props.n-1
  const currentIndex = props.currentIndex
  const Arr: number[]= [...Array(props.n).keys()]
  Arr && Arr.shift()
  return (
    <motion.div
      initial={{ y: '-40px' }}
      animate={{ y: 0 }}
      transition={{ stiffness: 100 , duration:1,ease:'easeOut'}}
    >
      <div className='step-nav'>
        {Arr && Arr.map((index)=>{
          return(
            <div className={`li-element ${index} ${index<=currentIndex ? "white":""} ${index===currentIndex?"active":""}`}>
              <div className="circle">
                <span id="img-number">{index<currentIndex ?<img src='./assets/img/done.svg' alt='done'/>:<span id='index'>{index}</span>}</span>
              </div>
              <span className={`step${index===lastindex?" last-child":""}`}>Step {index}</span>
              {index < lastindex ? 
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 9L9.35355 9.35355L9.70711 9L9.35355 8.64645L9 9ZM0.646447 1.35355L8.64645 9.35355L9.35355 8.64645L1.35355 0.646447L0.646447 1.35355ZM8.64645 8.64645L0.646447 16.6464L1.35355 17.3536L9.35355 9.35355L8.64645 8.64645Z" fill="white" fill-opacity={`${!(index<=currentIndex) ? "0.5":"1"}`}/>
                </svg>
              :""}
            </div>
          )
        })}
      </div>
    </motion.div>
  );
};

export default Nav;