import React from 'react';
import ReactDOM from 'react-dom';
import env from 'react-dotenv'
import App from './App';
import './style/index.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

const client = new ApolloClient({
  headers:{
   "Content-Type":"application/graphql",
   "x-api-key":`${env.ApiKey}`
 },
 uri: env.ApiUrl,
 cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
     <ApolloProvider client={client}>
      <App />
     </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

