import Footer from '../components/global/Footer';
import Nav from '../components/global/Nav';
import NavBar from '../components/global/NavBar';
import NavBar2 from '../components/global/NavBar2';
import Social from '../components/global/Social';
import PhoneInput from 'react-phone-number-input';
import { useState,  useEffect} from 'react';
import { setError, validateEmail } from '../components/kaba-signup/utils/utils';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useMutation } from '@apollo/client';
import { createApplication } from '../components/graphql/Mutation';
import ModalComponent from '../components/global/Modal';
import Captcha from '../components/global/Captcha';
import { useNavigate } from 'react-router-dom';
import env from 'react-dotenv';
import { useTranslation } from 'react-i18next';

const KabaContact = () => {
  const {t} = useTranslation()
  const courriel:any = t('page-contact.courriel.1',{returnObjects: true})
  const infos:any = t('global.global-infos',{returnObjects: true})
  const sujets:any = t('page-contact.contact-infos.sujet.1',{returnObjects: true})
  const [status, setStatus] = useState<string>("")
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [captchaIsValide, setCaptcha] = useState<boolean>(false)

  const [phoneValue, setPhoneValue] = useState<any>("")
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [topic, setTopic] = useState<string>('')
  const [message, setMessage] = useState<string>("")
  
  const [mutationFunc, {loading}] = useMutation(createApplication)
  const navigate = useNavigate()


  const handleSubmit = (e:any) => {
    e.preventDefault()
    let submit: boolean[] = []
    const inputs = [
      [name,"name"],
      [topic,"topic"],
      [message,"message"],
      [email,"email"]
    ]
    inputs.forEach((elem)=>{
      if(elem[0]===""){
        submit.push(false)
        setError(elem[1],"Ce champs ne peut pas être vide")
        setTimeout(() => {
          setError(elem[1],"")
        }, 3000);
      }
    })
    if( email && !validateEmail(email)){
      submit.push(false)
      setError("email","Email incorrect")
      setTimeout(() => {
        setError('email',"")
      }, 3000);
    }
    if(!isValidPhoneNumber(phoneValue)){
        submit.push(false)
        setError('phone',"Numéro de téléphone incorrect")
        setTimeout(() => {
          setError('phone',"")
        }, 3000);
      }
    if(captchaIsValide===false){
        submit.push(false)
        setError('message',"Erreur de vérification captcha")
        setTimeout(() => {
          setError('message',"")
        }, 3000);
    }
    if(submit.length===0 && captchaIsValide === true){
      const input = {
        type: 'CONTACT',
        name,
        phone_number: phoneValue,
        email,
        subject: topic,
        message,
        country:"tg"
      }
      mutationFunc({
        variables: {
          input
        }
      })
        .then(()=>{
          // navigate('/#home',{state:{name:'formulaire'}});
          setStatus("contact");
          setIsOpen(true);
        })
        .catch(()=>{
          setStatus("error");
          setIsOpen(true);
        })
    }
  }
  
  return (
    <div className='kaba-contact'>
      <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} status={status} />
      <div className="header">
        <Nav/>
        <NavBar/>
        <NavBar2/>
      </div>
      <div className="main">
        <div className="item1">
          <span id="title1">{t('page-contact.titre-principal')}</span>
          <p id="p1">{t('page-contact.titre-texte')}</p>
        </div>
        <div className='banner'></div>
        <div className="contact">
          <img src="assets/img/contact-vector.svg" alt="vector" />
          <img src="assets/img/group-124.svg" alt="svg" />

          <div className="telephone c">
            <img src="assets/img/calling.png" alt="" />
            <div className="info">
              <span id="f">{infos[2]}</span>
              <span id="s">(+228) 92 10 94 74</span>
            </div>
          </div>
          <div className="courriel c">
            <img src="assets/img/courriel.png" alt="" />
            <div className="info">
              <span id="f">{t('page-contact.courriel.0')}</span>
              <span id="s">{courriel[0]}</span>
              <span id="s">{courriel[1]}</span>
              <span id="s">{courriel[2]}</span>
            </div>
          </div>
          <div className="adresse c">
            <img src="assets/img/location.png" alt="" />
            <div className="info">
              <span id="f">{t('page-contact.adresse')}</span>
              <span id="s">Agbalepedo, 319 Rue AZIABOR, Lomé, Togo.</span>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <img src="assets/img/contact-bigvector.svg" alt="vector" />
          <span id="title2">{t('page-contact.titre')}</span>
          <div className="form">
            <form action=""onSubmit={handleSubmit}>
              <div className="bloc">
                <div className="left">
                  <div className="name input-div">
                    <label htmlFor="">{infos[0]}*</label>
                    <input type="text" value={name} onChange={(e)=>setName(e.target.value)}/>
                    <span id="error"></span>
                  </div>
                  <div className="phone input-div">
                  <label>{infos[2]}*</label>
                  <PhoneInput
                    defaultCountry='TG'
                    value={phoneValue}
                    onChange={(e)=>
                      {
                        setPhoneValue(e)
                      }
                    }
                    className="input-style"
                    type="text"
                  />
                  <span id="error"></span>
                </div>
                  
                </div>
                <div className="right">
                  <div className="email input-div">
                    <label htmlFor="">{infos[1]}*</label>
                    <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                    <span id="error"></span>
                  </div>
                  <div className="topic input-div">
                    <label htmlFor="">{t('page-contact.contact-infos.sujet.0')}*</label>
                    <select name="" className="input-style"onChange={(e)=>setTopic(e.target.value)} value={topic} defaultValue={""}>
                      <option value=""></option>
                      {sujets.map((elem:any)=>{
                        return(
                          <option key={elem} value={elem}>{elem}</option>
                        )
                      })}
                    </select>
                    {/* <input type="text" value={topic} onChange = {(e)=>setTopic(e.target.value)} /> */}
                    <span id="error"></span>

                  </div>
                </div>
              </div>
              <div className="message input-div">
                  <label htmlFor="">{t('page-contact.contact-infos.message')}*</label>
                  <textarea value={message} onChange={(e)=>setMessage(e.target.value)} ></textarea>
                  <span id="error"></span>
                  <Captcha setCaptchaValide={setCaptcha}/>

              </div>
              <button onClick={handleSubmit}>{loading===true ?<i className='fas fa-spinner fa-pulse'></i> :<img src="assets/img/send.svg" alt='send'/>}</button>
            </form>
          </div>
        </div>
        <div className="map">
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default KabaContact;