interface catégorie {
  img: string,
  name: string
}


interface imgName {
  src:string,
  name:string
}

interface catégorieImage{
  categorie: string
  img: imgName[]
}

export const categories: catégorie[] = [
  { 
    img: "icon-riz.png",
    name:"Riz / Molou"
  },
  { 
    img: "icon-atcheke.png",
    name:"Attièkè"
  },
  { 
    img: "icon-pizza.png",
    name:"Pizza"
  }
]

export const categoriesImages: catégorieImage[] = [
  {
    categorie: "Riz / Molou",
    img:[
      {src:"riz-makafui.png",name:"Makafui"},
      {src:"riz-hype.png",name:"Hype"},
      {src:"riz-mami.png",name:"Maami"},
      {src:"riz-loko.png",name:"Locoh-Donou"}
    ]
  },
  {
    categorie: "Attièkè",
    img:[
      {src:"atcheke-armandine.png",name:"Chez Armandine"},
      {src:"atcheke-forever.png", name:"Attieke De Forever"},
      {src:"atcheke-opera.png",name:"opera"},
      {src:"atcheke-armandine.png",name:"Chez Armandine"}
    ]
  },
  {
    categorie: "Pizza",
    img:[
      {src:"pizza-mrpizza.png",name:"Mr Pizza"},
      {src:"pizza-famous.png",name:"Randolph Famous"},
      {src:"pizza-norcina.png",name:"Pizza Norcina"},
      {src:"pizza-adams.png",name:"Chez Adams"}
    ]
  }
]