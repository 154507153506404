import { description } from '../ShipperForm';
import SectionWrapper from '../SectionWrapper';
import { useState, useEffect } from 'react';
import { setButton, setError } from '../../../../utils/utils';
import { useTranslation } from 'react-i18next';
interface Props{
  setPhoneDescription: any
  phoneDescription: description
  setWorkOptions: any
  workOptions: string
}
const Section3 = (props:Props) => {
  const {t} = useTranslation()
  const [selected, setSelected] = useState<string>(props.phoneDescription.have===true?"oui":"non")
  const handleInput = (e:any, inputType:string) => {
    switch (inputType) {
      case 'phone-description-smartBrand':
        props.setPhoneDescription({...props.phoneDescription, smartBrand: [e.target.value, false] })
        if(e.target.value===""){
          setButton('disable')
          setError("phone-description-smartBrand","ce champs ne peut pas être vide")
        }else {
          setError('phone-description-smartBrand', '')
          props.setPhoneDescription({...props.phoneDescription, smartBrand: [e.target.value, true] })
        }
        break;
      case 'phone-description-timePossession':
        props.setPhoneDescription({...props.phoneDescription, timePossession: [e.target.value, false] })
        if(e.target.value===""){
          setButton('disable')
          setError("phone-description-timePossession","ce champs ne peut pas être vide")
        }else {
          setError('phone-description-timePossession', '')
          props.setPhoneDescription({...props.phoneDescription, timePossession: [e.target.value, true] })
        }
        break;
      
      default:
        break;
    }
  }
  useEffect(()=>{
    if(
        (props.phoneDescription.have===true &&
          props.phoneDescription.smartBrand[1] === true &&
        props.phoneDescription.timePossession[1] === true) || props.phoneDescription.have === false
      ){setButton('active')}
      else{setButton('disable')}
  },[props])

  return (
    <SectionWrapper 
      sectionId={3}
      title={t('page-rejoindre.section-livreur.titres.2')}
      content={
        <div>
          <div className="choice smart">
            <div className="radio oui">
              <input
                type="radio"
                value="oui"
                checked={selected==="oui"}
                onClick={(e)=> props.setPhoneDescription({...props.phoneDescription, have:true})}
                onChange={(e)=>setSelected(e.target.value)}
              />
              <label>{t('page-rejoindre.section-livreur.oui-non.0')}</label>
            </div>
            <div className="radio non">
              <input
                type="radio"
                value="non"
                checked={selected==="non"}
                onClick={(e)=>{ props.setPhoneDescription({...props.phoneDescription, have:false})}}
                onChange={(e)=>setSelected(e.target.value)}
              />
              <label>{t('page-rejoindre.section-livreur.oui-non.1')}</label>
            </div>
          </div>
          {selected === "oui" &&
            <div className="inputs-container">
                <div className="input-left">
                  <div className="phone-description-smartBrand input-div">
                    <label>{t('page-rejoindre.section-livreur.livreur-infos.2')}*</label>
                    <input 
                      type="text"
                      value={props.phoneDescription.smartBrand[0]}
                      onChange={(e)=>handleInput(e,'phone-description-smartBrand')}
                      className='input-style'
                      />
                    <span id="error"></span>
                    {props.phoneDescription.smartBrand[1] === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
              
                  </div>
                  
                </div>
                <div className="input-right">
                  <div className="phone-description-timePossession input-div">
                  <label>{t('page-rejoindre.section-livreur.livreur-infos.5')}* </label>
                  <input 
                      placeholder='Ex: 6 mois ou 4 annnées'
                      value={props.phoneDescription.timePossession[0]}
                      onChange={(e)=>handleInput(e,'phone-description-timePossession')}
                      className='input-style'
                      />
                    <span id="error"></span>
                    {props.phoneDescription.timePossession[1] === true && <img className="done" src="./assets/img/done.svg" alt="done" />}
              
                  </div>
                </div>
            </div>}
          <div className="work-options">
              <p>{t('page-rejoindre.section-livreur.titres.3')}</p>
            <div className="en-semaine radio">
              <input 
                type="radio"
                value="in-week"
                checked={props.workOptions==="in-week"}
                onChange={(e)=>props.setWorkOptions(e.target.value)}
              />
              <label>{t('page-rejoindre.section-livreur.horaires.0')}</label>
            </div>
            <div className="en-weekends radio">
              <input 
                type="radio"
                value="weekend"
                checked={props.workOptions==="weekend"}
                onChange={(e)=>props.setWorkOptions(e.target.value)}
              />
              <label>{t('page-rejoindre.section-livreur.horaires.1')}</label>
            </div>
            <div className="toute-la-semaine radio">
              <input 
                type="radio"
                value="all"
                checked={props.workOptions==="all"}
                onChange={(e)=>props.setWorkOptions(e.target.value)}
              />
              <label>{t('page-rejoindre.section-livreur.horaires.2')}</label>
            </div>
          </div>
        </div>
      }/>
  );
};

export default Section3;