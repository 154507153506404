import React, { useContext, useState } from 'react';
import {Splide,  SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { useEffect } from 'react';
import { categories, categoriesImages } from '../datas/datas';
import AboutElement from '../../global/AboutElement';
import { AnimationContext } from '../../../App';
import { useTranslation } from 'react-i18next';




const About = () => {
  const [index, setIndex] = useState<number>(0) 
  const [index2, setIndex2] = useState<number>(0)
  const {t} = useTranslation()
  const {value3} = useContext(AnimationContext)
  const [state, setState] = value3
  
  const translate = (arg:number) => {
    const slider: HTMLElement | null = document.querySelector('.x-slider')
    const translatex:number = arg*1264
    slider && slider.scrollTo({
      left: translatex,
      behavior: 'smooth'
    })
  }
  const handleClick = (e:any,index:number)=>{
    setIndex(index)
    setIndex2(index*2)
    translate(index)
    const activeLink = document.querySelector('.about .mobile .active') 
      if(activeLink){
        activeLink.classList.remove("active")
        e.target.closest('.item').classList.add("active")

      }
  }
  
  useEffect(()=>{
    const target: HTMLElement | null = document.querySelector('.about1 .element-1 .right')
    const target2: HTMLElement | null = document.querySelector('.mobile .x-slider')
    const observer = new IntersectionObserver((entries,observer) =>{
      entries.forEach((entry)=>{
        if(!entry.isIntersecting){
          return
        }else{
          entry.target.classList.add('animation-right-3s')
          observer.unobserve(entry.target)
          
        }
      })
    },{threshold: 0.4} )
    if(target){
      if(state === false){
        observer.observe(target)
        setState(true)
      }else{
        target.style.opacity = "1"
      }
    }
    if(target2){
      if(state === false){
        setState( true)
        observer.observe(target2)
      }else{
        target2.style.opacity = "1"
      }
    }
  },[setState])
  return (
    <section className='about' >
      
      <div className='about1' id="about1">
        <div className="container">
          <div className="slider-container-first">
          <div className="element-1">
            <div className="first">
              
              <span  id="mod-2">{t('page-acceuil.section-galerie.titre.0')} <span id="primary" style={{display:"inline"}}>{t('page-acceuil.section-galerie.titre.1')}</span> </span>  
            </div>
          <div className="left">
            
            <div className="second">
                {categories && categories.map((item, index)=>{
                return(
                  <div className={`item ${index===0 ?  "active" : ""}`} key={`${item}-${index}`}  onClick={(e:any)=>{
                    setIndex(index)
                    setIndex2(index*2)
                    const activeLink = document.querySelector('.about .active') 
                    if(activeLink){
                      activeLink.classList.remove("active")
                      e.target.closest('.item').classList.add("active")
                    }}}>
                    <div className="img"><img src={`./assets/img/${item.img}`} alt="" /></div>
                    <span id="name">{item.name}</span>
                  </div>)
                })}
                <div className="empty"></div>
            </div>
            <div className="mobile">
              <div className="second-mobile">
                {categories && categories.map((item, index)=>{
                  return(
                    <div className={`item ${index===0 ?  "active" : ""}`} key={`${item}-${index}`} onClick={(e)=>{handleClick(e,index)}}>
                      <div className="img"><img src={`./assets/img/${item.img}`} alt="" /></div>
                      <span id="name">{item.name}</span>
                    </div>
                  )
                  })}
              </div>
              
              <div className="x-slider">
              {categoriesImages && categoriesImages.map((item,index)=>{
                  return(
                    <div className="slider-container" id={item.categorie}key={index} >
                      <div className="item">
                        <img src={`./assets/img/${item.img[0].src}`} alt={item.img[0].name}/>
                        <span id="img-name">{item.img[0].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                     
                        </div>
                      <div className="item">
                        <img src={`./assets/img/${item.img[1].src}`} alt={item.img[1].name}/>
                        <span id="img-name">{item.img[1].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                     
                      </div>
                      <div className="item">
                        <img src={`./assets/img/${item.img[2].src}`} alt={item.img[2].name}/>
                        <span id="img-name">{item.img[2].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                     
                      </div>
                      <div className="item">
                        <img src={`./assets/img/${item.img[3].src}`} alt={item.img[3].name}/>
                        <span id="img-name">{item.img[3].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                       
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            
          </div>
          <div className="right">
            <div className="splide-1">
               <Splide className='first-slide'
                  options={{
                    start: index,
                  }}
                  onUpdated={(splide, options)=>{
                    const s = options.start!==undefined? options.start : 0 
                    splide.go(`>${s}`)
                  }}
                  onActive={(splide, splideconmponent)=>{
                    const activeLink = document.querySelector('.about .second .active') 
                    const toActive = document.querySelectorAll('.about .element-1 .left .second .item ')
                    if(activeLink && toActive){
                      activeLink.classList.remove("active")
                      toActive[splideconmponent.index].classList.add("active")
                    }
                    setIndex(splideconmponent.index)
                  }}
                  >
                {categoriesImages && categoriesImages.map((item,index)=>{
                  return(
                  <SplideSlide key={`${item}-${index}`}>
                    <div className="slide" >
                      <div className="item">
                        <img src={`./assets/img/${item.img[0].src}`} alt={item.img[0].name}/>
                        <span id="img-name">{item.img[0].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                      </div>
                      <div className="item">
                        <img src={`./assets/img/${item.img[1].src}`} alt={item.img[1].name}/>
                        <span id="img-name">{item.img[1].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                      </div>
                      <div className="item">
                        <img src={`./assets/img/${item.img[2].src}`} alt={item.img[2].name}/>
                        <span id="img-name">{item.img[2].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                      </div>
                      <div className="item">
                        <img src={`./assets/img/${item.img[3].src}`} alt={item.img[3].name}/>
                        <span id="img-name">{item.img[3].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                      </div>
                    </div>
                  </SplideSlide>)
                })}
              </Splide>
            </div>
            <div className="splide-2">
            <Splide 
              options={{
                start: index2,
              }}
              onUpdated={(splide, options)=>{
                const s = options.start!==undefined? options.start : 0 
                if(splide.root.className.indexOf("first-slide")===-1)
                {splide.go(`>${s}`)}
                

      }
              }
              onActive={(splide, splideconmponent)=>{
                if(splide.root.className.indexOf("first-slide")===-1){
                  const activeLink = document.querySelector('.about .second .active') 
                  const toActive = document.querySelectorAll('.about .element-1 .left .second .item ')
                  
                  if(activeLink && toActive  ){
                    activeLink.classList.remove("active")
                    toActive[Math.floor(splideconmponent.index*0.5)].classList.add("active")
                  }
                }
                setIndex2(splideconmponent.index)
              }}
            >
              {categoriesImages && categoriesImages.map((item,index)=>{
                  return(<  >
                  <SplideSlide  >
                    <div className="slide second" >
                      <div className="item">
                        <img src={`./assets/img/${item.img[0].src}`} alt={item.img[0].name}/>
                        <span id="img-name">{item.img[0].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                     
                      </div>
                      <div className="item">
                        <img src={`./assets/img/${item.img[1].src}`} alt={item.img[1].name}/>
                        <span id="img-name">{item.img[1].name}</span>
                        <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                      </div>
                    </div>
                  </SplideSlide >
                  <SplideSlide >
                  <div className="slide second" >
                    <div className="item">
                      <img src={`./assets/img/${item.img[2].src}`} alt={item.img[2].name}/>
                      <span id="img-name">{item.img[2].name}</span>
                      <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                    </div>
                    <div className="item">
                      <img src={`./assets/img/${item.img[3].src}`} alt={item.img[3].name}/>
                      <span id="img-name">{item.img[3].name}</span>
                      <span id="stars"><img src="assets/img/4 Stars.svg" alt="rate" /></span>
                    </div>
                   </div>
                  </SplideSlide>
                  </>)
                })}
             
            </Splide>
            </div>
          </div> 
          </div>
          </div>
          <AboutElement/>
        </div>
      </div>
      <div className="about2" id="about2">
      <div className="container">
        <div className="element-1">
          <p id="mod-1">{t('page-acceuil.section-fonctionnement.titre.0')} <span id="primary">{t('page-acceuil.section-fonctionnement.titre.1')}</span>{t('page-acceuil.section-fonctionnement.titre.2')}</p>
          <p id="mod-2">{t('page-acceuil.section-fonctionnement.titre-texte')}</p>
        </div>
        <div className="element-2">
          <div className="item frame-1 ">
            <div className="img">
              <img className="mobile-vector m1"src="assets/img/frame1.svg" alt="frame" />
              <img className ="mobile-vector3"src="assets/img/mobile-vector4.svg" alt="vector" />
              <img src="assets/img/choisir-le-restaurant.svg" alt="choisir-restaurant" />
            </div> 
            <div className="title">{t('page-acceuil.section-fonctionnement.sous-titres.0')}</div> 
            <div className="description">{t('page-acceuil.section-fonctionnement.sous-titres-textes.0')}</div>
          </div>
          <div className="item frame-2">
            <div className="img">
              <img className="mobile-vector m2"src="assets/img/frame2.svg" alt="frame" />
              <img src="assets/img/selectionner-le-menu.svg" alt="selectionner-menu" />
            </div> 
            <div className="title">{t('page-acceuil.section-fonctionnement.sous-titres.1')}</div> 
            <div className="description">{t('page-acceuil.section-fonctionnement.sous-titres-textes.1')}</div>
          </div>
          <div className="item frame-3">
            <div className="img">
              <img className="mobile-vector m3"src="assets/img/frame3.svg" alt="frame" />
              <img src="assets/img/choisir-adresse-de-livraison.svg" alt="mode-de-paiement" />
            </div> 
            <div className="title">{t('page-acceuil.section-fonctionnement.sous-titres.2')}</div> 
            <div className="description">{t('page-acceuil.section-fonctionnement.sous-titres-textes.2')}</div>
          </div>
          <div className="item frame-4">
            <div className="img">
              <img className="mobile-vector m4"src="assets/img/frame4.svg" alt="frame" />
              
              <img src="assets/img/choisir-son-mode-de-paiement.svg" alt="adress-livraison" />

            </div> 
            <div className="title">{t('page-acceuil.section-fonctionnement.sous-titres.3')}</div> 
            <div className="description">{t('page-acceuil.section-fonctionnement.sous-titres-textes.3')}</div>
          </div>
          <div className="item frame-5">
            <div className="img">
              <img className ="mobile-vector m5"src="assets/img/frame5.svg" alt="frame" />
              <img src="assets/img/confirmez-sa-commande.svg" alt="confirmez-sa-commande" />
            </div> 
            <div className="title">{t('page-acceuil.section-fonctionnement.sous-titres.4')}</div> 
            <div className="description">{t('page-acceuil.section-fonctionnement.sous-titres-textes.4')}</div>
          </div>
          <div className="item frame-6">
            <div className="img">
              <img className ="mobile-vector m6"src="assets/img/frame6.svg" alt="frame" />
              <img className="mobile-vector2"src="assets/img/mobile-vector3.svg" alt="vector" />
              <img src="assets/img/regalez-vous.svg" alt="regalez-vous" />
            </div> 
            <div className="title">{t('page-acceuil.section-fonctionnement.sous-titres.5')}</div> 
            <div className="description">{t('page-acceuil.section-fonctionnement.sous-titres-textes.5')}</div>
          </div>
          <div className="mobile-vector">
            <img src="assets/img/mobile-vector2.svg" alt="vector" />
            <img src="assets/img/mobile-vector1.svg" alt="vector" />
            <img src="assets/img/mobile-vector2.svg" alt="vector" />
            
           
          </div>
          <div className="iframe del iframe-1"><img src="assets/img/iframe-1.svg" alt="iframe" /></div>
          <div className="iframe del iframe-2"><img src="assets/img/local_pizza_black_36dp.svg" alt="pizza-svg" /></div>
          <div className="iframe-3 del" ><img src="assets/img/Vector.svg" alt="vector" /></div>
          <div className="iframe del iframe-4"><img src="assets/img/bakery_dining_black_36dp.svg" alt="bakery" /></div>
          <div className="iframe del iframe-5"><img src="assets/img/Vector 3.svg" alt="vector" /></div>
          <div className="iframe del iframe-6"><img src="assets/img/Vector 2.svg" alt="vector" /></div>
          <div className="iframe del iframe-7"><img src="assets/img/Group 73.svg" alt="vector" /></div>
          <div className="iframe del iframe-9"><img src="assets/img/Group 74.svg" alt="vector" /></div>
          <div className="iframe-8 del "><img src="assets/img/Vector-1.svg" alt="vector"/></div>
          <div className="iframe del iframe-10"><img src="assets/img/dinning.svg" alt="dinning"/></div>
          <div className="iframe del iframe-11"><img src="assets/img/resp-vector1.svg" alt="vector" /></div>
          <div className=" iframe del iframe-12"><img src="assets/img/resp-vector2.svg" alt="vector" /></div>
          <div className="iframe-13 del "><img src="assets/img/resp-vector3.svg" alt="vector" /></div>
          <div className="iframe-14 del "><img src="assets/img/resp-vector4.svg" alt="vector" /></div>
          <div className="iframe-15 del "><img src="assets/img/resp-vector5.svg" alt="vector" /></div>
        </div>
      </div>
      </div>
      <div className="about3">
        <div className="container">
        <div className="img left">
          <div>
            <iframe
              className='img left'
              src="https://www.youtube.com/embed/rSUGDhb2o1I"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            />{" "}
        </div>
        </div>
        <div className="right">
          <div className="img"><img src="assets/img/pizza.png" alt="pizza" /></div>
          <span id="mod-1">{t('page-acceuil.section-video.titre.0')}{"  "}
          <br id="br"/>
          {t('page-acceuil.section-video.titre.1')}</span>
          <p>{t('page-acceuil.section-video.texte')}</p>
          <div className="buttons">
              <a href="https://play.google.com/store/apps/details?id=tg.tmye.kaba.brave.one&hl=fr">
              <div className="button">
                <div className="left"><img src="assets/img/play-store.svg" alt="play-store" /></div>
                <div className="rightb">
                  {t('global.play-store.0')}
                  <br/>
                  <span id="mod-4">Google Play</span>
              </div>   
                </div></a>
              <a href="http://apps.apple.com/us/app/kaba/id1513025430?l=fr&ls=1">
              <div className="button">
                <div className="left"><img src="assets/img/app-store.svg" alt="app-store" /></div>
                <div className="rightb">
                  {t('global.play-store.0')}
                <br/>
                <span id="mod-4">App Store</span>  
                </div>
              </div>
              </a>
              </div> 
        </div>
        </div>
      </div>

    </section>
  );
};
export default About;