import React from 'react';
import Wrapper1 from '../components/global/Wrapper1';
import Body from '../components/kaba-ML/Body';

const KabaML = () => {
  return (
    <div>
      <Wrapper1 
        name='ML'
        bannerTitle='MENTIONS LEGALES'
        content={<Body/>}
      />
    </div>
  );
};

export default KabaML;