import React from 'react';
import About from '../components/kaba-food/sections/About';
import Home from '../components/kaba-food/sections/Home';
import Join from '../components/kaba-food/sections/Join';
import Footer from '../components/global/Footer';
import Service from '../components/kaba-food/sections/Service';

const KabaFood = () => {
 
  return (
    <div className='kaba-food'>
      <Home />
      <Service/>
      <About/>
      <Join/>
      <Footer/>
    </div>
  );
};

export default KabaFood;