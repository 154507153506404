import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import React from 'react';
import { useState } from 'react';
import { useEffect, DependencyList } from 'react'

interface Props {
  imageFile: File | null
  imageRatio: number 
  setBase64File: any
  uploadFile?: boolean
  setUploadFile?: any
  uploadFile2?: boolean
  setUploadFile2?:any
  setCrop: any
  type?: string
  File:any
}

const CropImage = (props: Props) => {
  const [imgSrc, setImgSrc] = useState('')
  const [previewSrc, setPreviewSrc] = useState('')
  const imgRef = React.useRef<HTMLImageElement | null>(null)
  const [crop, setCrop] = useState<Crop>()
  const [imageWidth, setWidth] = useState<number>(0)
  const [imageHeight, setHeight] = useState<number>(0)
  const [completedCrop, setCompletedCrop] = useState<any>()

  function useDebounceEffect(fn: () => void, waitTime: number, deps?: DependencyList) {
    useEffect(() => {
      const t = setTimeout(() => {
        fn.apply(undefined,)
      }, waitTime)
  
      return () => {
        clearTimeout(t)
      }
    }, [deps])
  }

    useEffect(()=>{
      if (props.imageFile !== null) {
        setCrop(undefined)
        const reader = new FileReader()
        reader.addEventListener('load', () => {setImgSrc(reader.result?.toString() || '')
          
        })
        reader.readAsDataURL(props.imageFile)
  
      }
    },[props.imageFile])
  

  function onImageLoad(e: any) {
    if (!e.currentTarget) {
      return
    }
    imgRef.current = e.currentTarget
    const { width, height } = e.currentTarget
    setWidth(width)
    setHeight(height)

    const initialCrop:Crop = {

      unit: "px",
      x: 0,
      y: 0,
      width : width/2 ,
      height: (width/2)/props.imageRatio
        } 
    setCrop(initialCrop)
    setCompletedCrop(initialCrop)

    // This is to demonstate how to make and center a % aspect crop
    // which is a bit trickier so we use some helper functions.
  }
  
  const getCroppedImg = async (image:HTMLImageElement, crop: PixelCrop )=>{
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")
      if (!ctx) {
        throw new Error('No 2d context')
      }

      const scaleX= image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height

      const pixelRatio = 1

      canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
      canvas.height = Math.floor(crop.width * scaleX * pixelRatio) / props.imageRatio

      ctx.imageSmoothingQuality = 'high'

      const cropX = crop.x * scaleX
      const cropY = crop.y * scaleY

      const centerX = image.naturalWidth / 2
      const centerY = image.naturalHeight / 2
  
      ctx.save()

      ctx.translate(-cropX, -cropY)
      ctx.translate(centerX, centerY)
      ctx.translate(-centerX, -centerY)

      ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight)

      ctx.restore()
      const base64Image = canvas.toDataURL("image/jpeg", 1);
      setPreviewSrc(base64Image)
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current) {
        getCroppedImg(imgRef.current, completedCrop)
      }
    },
    100,
    [completedCrop]
  )
  useEffect(()=>{
    if (props.uploadFile === true){
      if(props.type === 'logo'){
        props.setBase64File({value: previewSrc, valide: true})
      }else if (props.type === 'resto'){
        const temp = {...props.File}
        temp.value && ( temp.value[0] = previewSrc)
        temp.valide = true
        props.setBase64File(temp)
      }
      props.setUploadFile(false)
      props.setCrop(false)
    } 
    if(props.uploadFile2 === true){
      const temp = {...props.File}
      temp.value && (temp.value[1] = previewSrc)
      props.setUploadFile2(false)  
    }
  },[props,previewSrc])
  return (
    <div className="img1">
      {Boolean(imgSrc) && (
        <ReactCrop
          crop={crop}
          className="first"
          onChange={(_, percentCrop) => {

            setCrop({
                unit: "px",
                x: (percentCrop.x/100)*imageWidth,
                y: (percentCrop.y/100)*imageHeight,
                width: (percentCrop.width/100)*imageWidth,
                height: ((percentCrop.width/100)*imageWidth)/ props.imageRatio
            })
            setCompletedCrop({
              unit: "px",
              x: (percentCrop.x/100)*imageWidth,
              y: (percentCrop.y/100)*imageHeight,
              width: (percentCrop.width/100)*imageWidth,
              height: ((percentCrop.width/100)*imageWidth)/ props.imageRatio
          })

          }}

          
        >
          
          <img
            alt="Crop me"
            src={imgSrc}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      {Boolean(previewSrc && completedCrop) && (
        <div className='second'>
          <img
            src={previewSrc}
            style={{
              border: '1px solid black',
              maxWidth: `min(100%, ${completedCrop?.width || 0}px)`,
            }}
            alt="Crop preview"
            />
            
        </div>
        
        
      )}
    </div>
  );
};

export default CropImage;