import React from 'react';
import Wrapper1 from '../components/global/Wrapper1';
import Body from '../components/kaba-CGU/Body';

const KabaCGU = () => {
  return (
    <div>
      <Wrapper1 
        name="CGU"
        bannerTitle="CONDITIONS GENERALES D'UTILISATION"
        content={<Body/>}
      />
    </div>
  );
};

export default KabaCGU;