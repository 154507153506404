import { NavHashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';



const Nav = () => {
  const {t} = useTranslation()
    // const setLinkActive = (arg:string)=>{
    //   const activeLink =  document.querySelector('.active')
    //   if(activeLink){activeLink.classList.remove('active')}
    //   const link = document.querySelector(`#a-${arg}`)
    //   if(link){link.classList.add('active')}
    // }
    

    // const setActive = (arg:string) => {
    //   if(window.location.hash === arg){
    //     setLinkActive(arg)
    //     return ''
    //   }
    // }

  return (
      <div className='nav-resp'>
        <ul >
          <li ><NavHashLink id="b-home" smooth to="/#home"   className={'b-home'}>{t('global.navigation.0')}</NavHashLink></li>
          <li><NavHashLink id='b-about'smooth to="/a-propos#top"  className={'b-about'}>{t('global.navigation.1')}</NavHashLink></li>
          <li><NavHashLink id='b-about2'smooth to="/#about2"  className={'b-about2'}>{t('global.navigation.2')}</NavHashLink></li>
          <li><NavHashLink id='b-join'smooth to="/rejoindre-kaba#kaba-signup"  className={'b-join'}>{t('global.navigation.3')}</NavHashLink></li>
          <li ><NavHashLink id='b-footer'smooth to="/contact#top"  className={'b-footer'}>{t('global.navigation.4')}</NavHashLink></li>
        </ul>
      </div>
  );
};

export default Nav;