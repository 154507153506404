import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import React, { useContext } from "react";
import Nav from "../../global/Nav";
import NavBar from "../../global/NavBar";
import NavBar2 from "../../global/NavBar2";
import { useEffect, useState } from "react";
import ModalComponent from "../../global/Modal";
import { useLocation } from "react-router-dom";
import { AnimationContext } from "../../../App";
import {useTranslation}  from 'react-i18next'
const Home = () => {
  const {value1} = useContext(AnimationContext)
  const [state, setState] = value1
  const [openModal, setModal] = useState<boolean>(true);
  const location = useLocation();
  const {t} = useTranslation()

  useEffect(() => {
    // if (location.state) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    //   setModal(true);
    //   window.history.replaceState({}, document.title);
    //   setTimeout(() => {
    //     setModal(false);
    //   }, 10000);
    // }
  }, []);

  useEffect(()=>{
    const target: HTMLElement | null = document.querySelector('.n-2')
    const target2: HTMLElement | null = document.querySelector(".el")
    const observer = new IntersectionObserver((entries,observer) =>{
      
      entries.forEach((entry)=>{
        if(!entry.isIntersecting){
          return
        }else{
          entry.target.classList.add('animation-2s')
          observer.unobserve(entry.target)
        }
      })
    },{threshold: 0.6} )
    if(target ){
      if(state=== false){
        observer.observe(target)
        setState(true)
      }else{
        target.style.opacity = "1"
      }
    }
    if(target2 ){
      if(state === false){
        observer.observe(target2)
        setState(true)
      }else{
        target2.style.opacity = "1"
      }
    }
  },[setState])

  return (
    <section className="home" id="home">
      {/* <ModalComponent
        isOpen={openModal}
        setIsOpen={setModal}
        home={true}
        status="success"
      /> */}
      <div className="home-container" id="cont">
        
        <div className="header">
          <Nav />
          <NavBar />
          <NavBar2 />
        </div>
        <div className="main">
          <div className="left">
            {/* <div className="item-1">
              <span>
                {" "}
                Google des Repas <img
                  src="assets/img/Moji 1.svg"
                  alt="emoji"
                />{" "}
              </span>
            </div> */}
            <div className="item-2">
              <span>
                {t('page-acceuil.section-acceuil.titre-principal.0')} <span id="mod-1">{t('page-acceuil.section-acceuil.titre-principal.1')} </span><br/>
                {t('page-acceuil.section-acceuil.titre-principal.2')} <span id="mod-1">{t('page-acceuil.section-acceuil.titre-principal.3')} </span><span>{t('page-acceuil.section-acceuil.titre-principal.4')} </span><br/>
                {t('page-acceuil.section-acceuil.titre-principal.5')} <span id="mod-1">{t('page-acceuil.section-acceuil.titre-principal.6')} </span><br/>

                
              </span>
                
            </div>
            <div className="item-3">
              <span id="mod-2">{t('global.slogan')}</span>
              <br />
            </div>
            <div className="resp item2">
              <img src="assets/img/resp1-vector1.svg" alt="vector" />
            </div>
            <div className="resp item1">
              <div className="cont2 el">
              <Splide
                options={{
                  type:"loop",
                  autoplay: true,
                  interval: 6000,
                  rewind: true,
                }}
              >
                <SplideSlide>
                  <div className="img ">
                    <img src="./assets/img/boto.png" alt="" />
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="img">
                    <img src="./assets/img/Burger.png" alt="" />
                  </div>
                </SplideSlide>
                <SplideSlide>
                  <div className="img tab-3">
                    <img src="./assets/img/pasta.png" alt="" />
                  </div>
                </SplideSlide>
              </Splide>
              </div>
            </div>
            <div className="download-text">
             {t('page-acceuil.section-acceuil.telecharger-application')}
            </div>
            <div className="item-4">
              <a href="https://play.google.com/store/apps/details?id=tg.tmye.kaba.brave.one&hl=fr">
                <div className="button">
                  <div className="left">
                    <img src="assets/img/play-store.svg" alt="play-store" />
                  </div>
                  <div className="right">
                    {t('global.play-store.0')}
                    <br />
                    <span id="mod-4">Google Play</span>
                  </div>
                </div>
              </a>
              <a href="http://apps.apple.com/us/app/kaba/id1513025430?l=fr&ls=1">
                <div className="button">
                  <div className="left">
                    <img src="assets/img/app-store.svg" alt="app-store" />
                  </div>
                  <div className="right">
                    {t('global.play-store.0')}
                    <br />
                    <span id="mod-4">App Store</span>
                  </div>
                </div>
              </a>
            </div>
            <div className="item-5">
              <ul>
                <li>
                  <a href="https://www.facebook.com/kabadelivery">
                    <img src="assets/img/Facebook.svg" alt="facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/kaba_delivery/">
                    <img src="assets/img/Instagram.svg" alt="instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/kaba-delivery-sarl/">
                    <img src="assets/img/Linkedin.svg" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/kabadelivery">
                    <img src="assets/img/Twitter.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="image-container">
            <img className="n-1" src="assets/img/burger-bg.svg" alt="" />
            <div className="cont n-2">
            <Splide
              options={{
                type:'loop',
                autoplay: true,
                interval: 6000,
                rewind: true,
              }}
            >
              <SplideSlide >
                <div className="img desk-1">
                  <img src="./assets/img/Burger.png" alt="" />
                  {/* <img className="" src="assets/img/smoke.svg" alt="burger" /> */}
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="img">
                  <img src="./assets/img/boto.png" alt="" />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="img desk-3">
                  <img src="./assets/img/pasta.png" alt="" />
                </div>
              </SplideSlide>
            </Splide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
