import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
interface Props {
  setChoice: any
}

const Choice = (props: Props) => {
  const {t} = useTranslation()
  const [selected, setSelected] = useState<string>("partner")

  const handleClick = () => {
    window.innerWidth < 600 && window.scrollTo({top:0})
    props.setChoice(selected)
  }
  return (
    
    <div className='choice'>
      <motion.div
        initial={{y:'200px'}}
        animate={{ y: 0 }}
        transition={{ stiffness: 100 , duration:0.8,ease:'easeOut'}}
      >
    <div className="choice-header">
      <p id="choice-description">{t('page-rejoindre.section-choix.titre')} </p>
    </div>
    <div className="choice-main">
      <div className="radio partner" onClick={()=>setSelected("partner")}>
        <input 
          type="radio"
          value="partner"
          name='partner'
          checked={selected==="partner"}
          onChange={(e)=>setSelected(e.target.value)}
          />
        <label htmlFor="partner">{t('page-rejoindre.section-choix.partenaire.0')}</label>
        <p id='description' onClick={()=>setSelected("partner")}>{t('page-rejoindre.section-choix.partenaire.1.0')}
        <br/>
        {t('page-rejoindre.section-choix.partenaire.1.1')}
        </p>
      </div>
      <div className="radio shipper" onClick={()=>setSelected("shipper")}>
        <input 
          type="radio" 
          value="shipper" 
          name='shipper'
          checked={selected==="shipper"}
          onChange={(e)=>setSelected(e.target.value)}
          />
        <label htmlFor='shipper'>{t('page-rejoindre.section-choix.livreur.0')}</label>
        <p id='description'>
        {t('page-rejoindre.section-choix.livreur.1.0')}
        <br/>
        <br/>
        {t('page-rejoindre.section-choix.livreur.1.1')}
         </p>
      </div>
    </div>
    </motion.div>
    <button onClick={handleClick}>{t('page-rejoindre.button.2')}</button>
    </div>
  );
};

export default Choice;