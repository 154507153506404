import Nav from "./Nav";
import Section1 from "./partner-form/Section1";
import Section2 from "./partner-form/Section2";
import Section3 from "./partner-form/Section3";
import { useState} from "react";
import PrevNext from "./PrevNext";
import { motion } from "framer-motion";
import ModalComponent from "../../../../global/Modal";
import { useMutation } from "@apollo/client";
import { createApplication } from "../../../../graphql/Mutation";

export interface inputTextField {
  value: string;
  valide: boolean;
}

export interface inputFileField {
  value: File | null;
  valide: boolean;
}

export const initialValue: inputTextField = {
  value: "",
  valide: false,
};
export const initialValueFile: inputFileField = {
  value: null,
  valide: false,
};
interface Props {
  setChoice: any;
}


const PartnerForm = (props: Props) => {
  const [index, setIndex] = useState<number>(1);
  const [state, setState] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  
  const [mutationFunc, {loading}] =
    useMutation(createApplication);

  const [whoIam, setWhoIam] = useState<inputTextField>({
    value: "Restaurant",
    valide: false,
  });

  const [name, setName] = useState<inputTextField>(initialValue);
  const [email, setEmail] = useState<inputTextField>(initialValue);
  const [phone, setPhone] = useState<inputTextField>(initialValue);
  const [adress, setAdress] = useState<inputTextField>(initialValue);
  const [ville, setVille] = useState<inputTextField>({value:"Lomé",valide:true});
  const [pays, setPays] = useState<inputTextField>({value:"tg",valide:true});
  const [logo, setLogo] = useState<inputFileField>(initialValueFile);
  const [picture1, setPicture1] = useState<inputFileField>(initialValueFile)
  const [picture2, setPicture2] = useState<inputFileField>(initialValueFile)
  const [existence, setExistence] = useState<inputTextField>(initialValue);
  const [sell, setSell] = useState<inputTextField>(initialValue);
  const [why, setWhy] = useState<inputTextField>(initialValue);
  const handleSubmit = (e: any) => {
    const input = {
      type: "PARTNER",
      name: name.value,
      email: email.value,
      what: whoIam.value,
      city: ville.value,
      country: pays.value,
      lifetime: existence.value,
      logo: logo.value,
      picture1: picture1 ? picture1.value : "",
      picture2: picture2 ? picture2.value : "",
      weekly_sales: sell.value,
      phone_number: phone.value,
      purpose: why.value,
      district: adress.value,
    };
    mutationFunc({
      variables: {
        input,
      },
    })
      .then(() => {
        setStatus("success");
        setIsOpen(true);
        // navigate('/#home',{state:{name:'formulaire'}});
      })
      .catch((err) => {
        console.log('error',err)
        setStatus("error");
        setIsOpen(true);
      });
  };

  return (
    <div className="partner-form">
      <Nav n={4} currentIndex={index} />
      <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} status={status}/>
      <form>
        {index === 1 && (
          <motion.div
            initial={{ x: state === false ? "20vw" :"-10vw", opacity :1} }
            animate={{ x: 0 , opacity:1}}
            transition={{ stiffness: 100, duration: 0.7, ease: "easeOut" }}
          >
            <Section1
              whoIam={whoIam}
              setWhoIam={setWhoIam}
              setState={setState}
              state={state}
            />
          </motion.div>
        )}
        {index === 2 && (
          <motion.div
          initial={{ x: state === false ? "20vw" :"-10vw", opacity :1} }
            animate={{ x: 0 ,opacity:1 }}
            transition={{ stiffness: 100, duration: 0.7, ease: "easeOut" }}
          >
            <Section2
              setName={setName}
              name={name}
              setEmail={setEmail}
              email={email}
              setVille={setVille}
              ville={ville}
              setPhone={setPhone}
              phone={phone}
              setAdress={setAdress}
              adress={adress}
              setPays={setPays}
              pays={pays}
              setLogo={setLogo}
              logo={logo}
              picture1={picture1}
              picture2={picture2}
              setPicture1={setPicture1}
              setPicture2={setPicture2}
            />
          </motion.div>
        )}

        {index === 3 && (
          <motion.div
          initial={{ x: state === false ? "20vw" :"-10vw", opacity :0} }
          animate={{ x: 0 ,opacity:1 }}
            transition={{ stiffness: 1500, duration: 0.7, ease: "easeOut" }}
          >
            <Section3
              setExistence={setExistence}
              existence={existence}
              setSell={setSell}
              sell={sell}
              setWhy={setWhy}
              why={why}
            />
          </motion.div>
        )}
      </form>
      <PrevNext
        setIndex={setIndex}
        state={state}
        setState={setState}
        currentIndex={index}
        lastIndex={3}
        setChoice={props.setChoice}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </div>
  );
};

export default PartnerForm;
