import React from 'react';

const Body = () => {
  return (
    <div>
      <div className="title">
        <h1 id="title">Informations & confidentialité</h1>
      </div>
      <div className="content">
      <p><span>1.</span> Là où, nous vous demandons des informations pour vous livrer les produits et services, vous acceptez de nous donner lesdites informations, complètes et exactes.
      </p>
      <p><span>2.</span> Vous nous autorisez à utiliser, stocker ou traiter vos informations personnelles afin de vous livrer les produits et/ou services commandés, et à des fins « marketing », de production de statistiques et de contrôle.
      </p>
      <p><span>3.</span> Vous avez le droit de demander une copie des informations personnelles ou tout autre information, que nous possédons sur vous. Merci de nous contacter si vous souhaitez recevoir les informations.
      </p>
      <p><span>4.</span> Nous nous interdisons d’utiliser vos données personnelles à des fins contraires à notre activité et qui peuvent vous nuire. Kaba, s’engage à travers cet accord, à respecter l’anonymat de vos données Personnelles
      </p>
      <p><span>5.</span> Nous vous informons, que certaines de vos informations sont stockées dans la base de données des opérateurs de téléphonie mobile Togocel et Moov.
      </p>
      <p>Nos portails de paiement mobile protègent notre activité commerciale en ligne contre toute tentative de fraude, vos transactions sont donc aussi protégées. La sécurité étant primordiale, Paygate (Principal partenaire et portail de paiement mobile de Kaba) à toutes les étapes, assure que : • Les numéros de téléphone soient encryptés • Toutes les communications soient encryptées et signées via SSL/TLS • Le système PayGateGlobal est protégé contre les risques de sécurité connus tels que les Dénis de services, les injections SQL... • Les données sont sauvegardées par incrément et répliqués sur des serveurs en différentes locations géographiques pour une haute accessibilité et un taux de récupération rapide en cas de désastre, cependant très improbable. Source : <a href='https://www.paygateglobal.com/guide'>https://www.paygateglobal.com/guide</a>
      </p>
      </div>
    </div>
  );
};

export default Body;


