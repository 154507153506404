import SectionWrapper from "../SectionWrapper";
import PhoneInput from "react-phone-number-input";
import React, { useState, useEffect } from "react";
import { checkEmailField, checkEmptyField, checkPhoneField, setButton } from "../../../../utils/utils";
import { inputTextField, inputFileField } from "../PartnerForm";
import UploadFiles from "../../../../../global/UploadFiles";
import { useTranslation } from 'react-i18next';

interface Props {
  setName: any;
  name: inputTextField;
  setEmail: any;
  email: inputTextField;
  setVille: any;
  ville: inputTextField;
  setAdress: any;
  adress: inputTextField;
  setPays: any;
  pays: inputTextField;
  setLogo: any;
  logo: inputFileField;
  setPhone: any;
  phone: inputTextField;
  setPicture1: any;
  picture1: inputFileField;
  setPicture2: any;
  picture2: inputFileField;
}

const Section2 = (props: Props) => {
  const {t} = useTranslation()
  const [phoneValue, setPhoneValue] = useState<any>();
  const IS = "input-style";
  const infos:any = (t('global.global-infos',{returnObjects: true}))
  const villes:any = (t('global.villes',{returnObjects: true}))
  const pays:any = (t('global.pays',{returnObjects: true}))
  const handleInput = (
    e: any,
    inputType: string,
    setState: React.Dispatch<React.SetStateAction<any>>
  ) => {
    if (["name", "ville", "adress", "pays"].indexOf(inputType) !== -1) {
      checkEmptyField(e,inputType,setState)
    }
    if (inputType === "email") {
      checkEmailField(e,setState)
    }
    if (inputType === "phone") {
      checkPhoneField(e, setState)
    }
  };
  useEffect(() => {
    if (
      props.adress.valide === true &&
      props.email.valide === true &&
      props.name.valide === true &&
      props.pays.valide === true &&
      props.phone.valide === true &&
      props.ville.valide === true &&
      props.picture1.valide === true &&
      props.picture2.valide === true &&
      props.logo.valide === true
    ) {
      setButton("active");
    } else {
      setButton("disable");
    }
  }, [props]);
  return (
    <SectionWrapper
      sectionId={2}
      title={t('page-rejoindre.section-partenaire.titres.1')}
      content={
        <div>
          {/* {crop===true && ((type===false) ? 
            <CropApp 
              imageFile={imageFile}
              imageRatio={1}
              setCrop={setCrop}
              setFile={props.setLogo}
              File={props.logo}
              type={'logo'}
              
            />: (imagesFile? 
            <CropApp
              imageFile={imagesFile[0]}
              imageFile2={imagesFile[1]}
              imageRatio={16/9}
              setFile={props.setPics}
              setCrop={setCrop}
              File={props.pics}
              type={'resto'}
            />:<></>
              ))
          } */}
          <div className="inputs-container">
            <div className="input-left">
              <div className="name input-div">
                <label>{infos[0]}*</label>
                <input
                  type="text"
                  value={props.name.value}
                  onChange={(e) => handleInput(e, "name", props.setName)}
                  className={IS}
                />
                <span id="error"></span>
                {props.name.valide === true && (
                  <img
                    className="done"
                    src="./assets/img/done.svg"
                    alt="done"
                  />
                )}
              </div>
              <div className="email input-div">
                <label htmlFor="email">{infos[1]}* </label>
                <input
                  type="text"
                  id="email"
                  value={props.email.value}
                  onChange={(e) => handleInput(e, "email", props.setEmail)}
                  className={IS}
                />
                <span id="error"></span>
                {props.email.valide === true && (
                  <img
                    className="done"
                    src="./assets/img/done.svg"
                    alt="done"
                  />
                )}
              </div>
              <div className="phone input-div">
                <label htmlFor="phone">{infos[2]}* </label>
                <PhoneInput
                  className={IS}
                  defaultCountry="TG"
                  value={phoneValue || props.phone.value}
                  onChange={(e) => {
                    setPhoneValue(e);
                    handleInput(e, "phone", props.setPhone);
                  }}
                />
                <span id="error"></span>
                {props.phone.valide === true && (
                  <img
                    className="done"
                    src="./assets/img/done.svg"
                    alt="done"
                  />
                )}
              </div>
            </div>
            <div className="input-right ">
              

              <div className="adress input-div">
                <label htmlFor="adress">{infos[3]}*</label>
                <input
                  type="text"
                  id="adress"
                  value={props.adress.value}
                  onChange={(e) => handleInput(e, "adress", props.setAdress)}
                  className={IS}
                />
                <span id="error"></span>
                {props.adress.valide && (
                  <img
                    className="done"
                    src="./assets/img/done.svg"
                    alt="done"
                  />
                )}
              </div>
              <div className="ville input-div">
                <label htmlFor="ville">{infos[4]}*</label>
                <select className= {IS}name="" onChange={(e)=>handleInput(e,'ville',props.setVille)} value={props.ville.value}>
                      {villes.map((elem:any)=>{
                        return(
                          <option key={elem} value={elem}>{elem}</option>
                        )
                      })}
                    </select>
                {/* <input
                  type="text"
                  id="ville"
                  value={props.ville.value}
                  onChange={(e) => handleInput(e, "ville", props.setVille)}
                  className={IS}
                /> */}
                <span id="error"></span>
                {props.ville.valide === true && (
                  <img
                    className="done"
                    src="./assets/img/done.svg"
                    alt="done"
                  />
                )}
              </div>
              <div className="pays input-div">
                <label htmlFor="pays">{infos[5]}*</label>
                <select className= {IS} onChange={(e)=>handleInput(e, 'pays', props.setPays)} value={props.pays.value}>
                  {pays.map((elem:any)=>{
                    return(
                      <option key={elem} value="tg">{elem}</option>
                    )
                  })}
                </select>
                {/* <input
                  type="text"
                  id="pays"
                  value={props.pays.value}
                  onChange={(e) => handleInput(e, "pays", props.setPays)}
                  className={IS}
                /> */}
                <span id="error"></span>
                {props.pays.valide === true && (
                  <img
                    className="done"
                    src="./assets/img/done.svg"
                    alt="done"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="logo">
            <UploadFiles
              n={1}
              states={[props.logo]}
              setStates={[props.setLogo]}
              title={t('page-rejoindre.section-partenaire.partenaire-infos.0')}
            />
          </div>
          <div className="pictures">
            <UploadFiles
              n={2}
              states={[props.picture1, props.picture2]}
              setStates={[props.setPicture1, props.setPicture2]}
              title={t('page-rejoindre.section-partenaire.partenaire-infos.1')}
            />
          </div>
          {/* <div className="logo-input relative input-div" >
            <input type="file" className="file-input" onChange={(e)=>handleInput(e, "logo")}/>
            <div className="logo">
              <img src="./assets/img/logo-upload.svg" alt="" />
              <span>Télécharger votre LOGO (format 1/1 : carré)</span>
              {props.logo.value && <img
                className="done file"
                src="./assets/img/done.svg"
                alt="done"
              />}
            <span id="error"></span>

            </div>
          </div> */}
          {/* <div className="pics-input relative input-div">
            <input type="file" className="file-input" onChange={(e)=>handleInput(e, "pics")} multiple={true} />
            <div className="logo">
              <img src="./assets/img/logo-upload.svg" alt="" />
              <span>Télécharger deux (02) images de votre Etablissement (format 16/9)</span>
              {props.pics.valide === true && <img
                className="done file"
                src="./assets/img/done.svg"
                alt="done"
              />}
            <span id="error"></span>
            </div>
          </div> */}
        </div>
      }
    />
  );
};

export default Section2;
