import React from 'react';
import CropImage from './CropImage';
import { useEffect, useState } from 'react';

interface Props{
  imageFile: File | null
  imageFile2?: File | null 
  imageRatio: number
  setFile: any
  setCrop: any
  File: any
  type: string

}


const CropApp = (props:Props) => {
  const [uploadFile, setUploadFile] = useState<boolean>(false)
  const [uploadFile2, setUploadFile2] = useState<boolean>(false)
  const handleClick = (e:any)=>{
    e.preventDefault()
    setUploadFile(true)
    if(props.imageFile2){
      setUploadFile2(true)
    }
  }
  window.scrollTo({top:0})
  return (
    <div className='crop-app' >
      <div className="crop-container">
          <CropImage 
            imageFile={props.imageFile}
            imageRatio={props.imageRatio}
            setBase64File={props.setFile}
            uploadFile={uploadFile}
            setUploadFile={setUploadFile}
            setCrop={props.setCrop}
            type={props.type}
            File={props.File}
            />
         { props.imageFile2 && <CropImage 
            imageFile={props.imageFile2}
            imageRatio={props.imageRatio}
            setBase64File={props.setFile}
            uploadFile2={uploadFile2}
            setUploadFile2={setUploadFile2}
            setCrop={props.setCrop}
            File={props.File}
            />}
            <div className="buttons">
              <button onClick={handleClick}>Valider</button>
              <button onClick={()=>props.setCrop(false)}>Annuler</button>
            </div>
          <div className="img2">
          <div className="first"></div>
            <div className="second"></div>
        </div>
      </div>
    </div>
  );
}; 

export default CropApp;