import React from 'react';
import NavBar from '../../global/NavBar';
import Left from './home/Left';
import Right from './home/Right';
import NavBar2 from '../../global/NavBar2';
import Nav from '../../global/Nav';

const Home = () => {
  return (
    <div className='signup'>
    <div className="header">
      <Nav/>
      <NavBar/>
      <NavBar2 fixed={false}/>
    </div>
    <div className='signup-home' id='kaba-signup'>
      <Left/>
      <Right/>
    </div>
    </div>
  );
};

export default Home;