import React from 'react';
import Choice from './right/Choice';
import PartnerForm from './right/PartnerForm';
import ShipperForm from './right/ShipperForm';
import { useState } from 'react';
import Home from './right/Home';

const Right = () => {
  const [choice, setChoice] = useState<string>('home')

  return (
    <div className='right-part'>
      {choice==='choice' && <Choice setChoice={setChoice}/>}
      {choice==='partner' && <PartnerForm setChoice={setChoice}/>}
      {choice==='shipper' && <ShipperForm setChoice={setChoice}/>}
      {choice==='home' && <Home setChoice={setChoice}/>}
    </div>
  );
};

export default Right;