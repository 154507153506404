import { isValidPhoneNumber } from 'react-phone-number-input';
export const validateEmail = (email:string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const setButton = (type:string) => {
  const buttonNext = document.querySelector(".button-next")
  if(type==='active'){
    buttonNext?.classList.remove('disabled')
    buttonNext?.removeAttribute("disabled")
  }
  else if (type==='disable'){
    buttonNext?.classList.add('disabled')
    buttonNext?.setAttribute("disabled","true")
  }
}

export const setError = (inputType:string, message:string ) => {
  const span: Element | null = document.querySelector(`.input-div.${inputType} #error`)
  if(span){
    span.innerHTML = message
  }
}

export const setDone = (inputType:string, action:string)=>{
  const img:HTMLElement | null = document.querySelector(`.input-div.${inputType} .done`)
  if(action==="visible" && img){
    img.style.display = "block"
  }
  else if(action === 'none' && img){
    img.style.display = 'none'
  }
}

export const checkEmptyField = (e:any,inputType:string,setState:any)=>{
  setState({ value: e.target.value, valide: false });
  if (e.target.value === "") {
    setButton("disable");
    setError(inputType, "ce champs ne peut pas etre vide");
  } else {
    setState({ value: e.target.value, valide: true });
    setError(inputType, "");
  }
}

export const checkEmailField = (e:any,setState:any)=>{
  setState({ value: e.target.value, valide: false });
  if (validateEmail(e.target.value)) {
    setState({ value: e.target.value, valide: true });
    setError("email", "");
  } else {
    setState({ value: e.target.value, valide: false });
    setError("email", "Email Incorrect");
  }
}

export const  checkPhoneField = (e:any, setState:any) =>{
  setState({ value: e, valide: false });
  if (e !== undefined && !isValidPhoneNumber(e)) {
    setButton("disable");
    setError("phone", "Numero incorrect");
  } else {
    setState({ value: e, valide: true });
    setError("phone", "");
  }
  if (e === undefined) {
    setState({ value: e, valide: false });
  }
}

export const resizeImage = (base64Str:string, maxWidth = 800, maxHeight = 800) => {
  return new Promise((resolve) => {
    let img = new Image()
    img.src = base64Str
    img.onload = () => {
      let canvas = document.createElement('canvas')
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      canvas.width = width
      canvas.height = height
      let ctx:any = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)
      resolve(canvas.toDataURL())
    }
  })
}