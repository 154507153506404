import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import JoinElement from '../../global/JoinElement';
import { useEffect, useState, useContext } from 'react';
import { AnimationContext } from '../../../App';
import { useTranslation } from 'react-i18next';

const Join = () => {
  const [kabafood , setKabaFood] = useState<any>('')
  const {t} = useTranslation()
  const {value5} = useContext(AnimationContext)
  const [state, setState] = value5

  useEffect(()=>{
    const kabafood = document.querySelector('.kaba-food')
    setKabaFood(kabafood)
  },[])
  useEffect(()=>{
    const target: HTMLElement | null = document.querySelector(".join .slider")
    if(kabafood){
      const observer = new IntersectionObserver((entries,observer)=>{
      entries.forEach((entry)=>{
        if(!entry.isIntersecting){
          return
        }else{
          entry.target.classList.add('animation-right-3s')
          observer.unobserve(entry.target)
        }
      })
      },{threshold: 0.5})
      if(target){
          if(state === false){
            observer.observe(target)
            setState(true)
          }else{
            target.style.opacity="1"
          }
      }
    }
  },[kabafood,setState])
  return (
    <section className='join' id="join">
      <div className="container">
        <JoinElement/>
        <div className="element-2">
          <div className="container">
            <div className="left">
              <div className="img">
                <img src='./assets/img/group-122.png' alt='shipper'/>
              </div>
            </div>
            <div className="right">
              <p id="mod-2">{t('page-acceuil.section-avis.titre.0')} <span id='primary' style={{display:"inline"}}> {t('page-acceuil.section-avis.titre.1')}</span> {t('page-acceuil.section-avis.titre.2')}</p>
              <div className="slider">
                <Splide options={{
                type:'loop',
                autoplay:true,
                interval:7000,
                rewind: true,
              }} >
                  <SplideSlide>
                    <div className="item">
                      <div className="comment">
                      {t('page-acceuil.section-avis.avis.0')}
                      </div>
                      <div className="info">
                        <div className="pic"><img src="assets/img/avatar-m.png" alt="avatar" /></div>
                        <span id="name">Emmanuel</span>
                      </div>
                      <div className="rate"><img src="assets/img/4 Stars.svg" alt="rate" /></div>
                    </div>
                  </SplideSlide>
                  <SplideSlide>
                    <div className="item">
                      <div className="comment">
                      {t('page-acceuil.section-avis.avis.1')}
                      </div>
                      <div className="info">
                        <div className="pic"><img src="assets/img/avatar-f.png" alt="avatar" /></div>
                        <span id="name">Anna</span>
                      </div>
                      <div className="rate"><img src="assets/img/4 Stars.svg" alt="rate" /></div>
                    </div>
                  </SplideSlide>
                  <SplideSlide>
                    <div className="item">
                      <div className="comment">
                      {t('page-acceuil.section-avis.avis.2')}
                      </div>
                      <div className="info">
                        <div className="pic"><img src="assets/img/avatar-m.png" alt="avatar" /></div>
                        <span id="name">Palaman</span>
                      </div>
                      <div className="rate"><img src="assets/img/4 Stars.svg" alt="rate" /></div>
                    </div>
                  </SplideSlide>
                </Splide>
              </div>
            </div>
          </div>
        </div>
        <div className="element-3">
            <div className="first">
              <span id="mod-1">{t('page-acceuil.section-partenaire.titre')} </span>
              <p id="mod-2">{t('page-acceuil.section-partenaire.texte')} </p>
            </div>
            <div className="second">
              <div className='container'>
                <div className="row-1">
                <div className="item">
                  <div className="img"><img src="assets/img/Rectangle 60.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/Rectangle 61.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/Rectangle 62.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/Rectangle 63.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/Rectangle 64.png" alt="partner" /></div>
                </div>
              </div>
              <div className="row-1">
                <div className="item">
                  <div className="img"><img src="assets/img/Rectangle 65.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/Rectangle 66.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/partner1.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/partner2.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/partner3.png" alt="partner" /></div>
                </div>
              </div>
              <div className="row-1">
                <div className="item">
                  <div className="img"><img src="assets/img/partner4.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/partner5.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/partner6.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/partner7.png" alt="partner" /></div>
                </div>
                <div className="item">
                  <div className="img"><img src="assets/img/Rectangle 67.png" alt="partner" /></div>
                </div>
              </div>
              </div>
              
            </div>
        </div>
      </div>
    </section>
  );
};

export default Join;