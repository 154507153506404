import React from 'react';
import Footer from './Footer';
import Nav from './Nav';
import NavBar from './NavBar';
import NavBar2 from './NavBar2';

interface Props {
  name:string
  bannerTitle:string
  content:any
}

const Wrapper1 = (props:Props) => {
  return (
    <div className={`wrapper ${props.name}`}  id={props.name}>
      <div className={`header ${props.name}`}>
        <Nav/>
        <NavBar/>
        <NavBar2/>
      </div>
      <div className={`main ${props.name}`} >
        <div className={`banner ${props.name}`}>
          <img id="points"src="./assets/img/points.svg" alt="points" />
          <span id="title">{props.bannerTitle}</span>
          <img id="contact"src="./assets/img/contact.svg" alt="contact" />
        </div>
        <div className={`main-content ${props.name}`}>
          {props.content}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Wrapper1;