import { t } from 'i18next';
import React from 'react';
import { NavHashLink } from 'react-router-hash-link';

const JoinElement = () => {
  return (
    <div className="global element-1">
      <div className="img"><img className="vector"src="assets/img/join-vector.svg" alt="vector" /></div>
      <p id="mod-1">{t('page-acceuil.section-joindre.titre.0')} <span id="mod-11">KABA<br/>DELIVERY</span> 
      </p>
      <p id="mod-2">{t('page-acceuil.section-joindre.texte')} </p>
      <span id="mod-4"><img src="assets/img/Moji 1.png" alt="" /></span>
      <NavHashLink to="/rejoindre-kaba#kaba-signup"><button>{t('page-acceuil.section-joindre.button')}</button></NavHashLink>
  </div>
  );
};

export default JoinElement;