
export const fixNav = (arg:string) => {
  /* Algo menu collant
      si le menu sort de l'ecran alors il sera fixe-*/

  var scrollY = function() {
      var supportPageOffset = window.pageXOffset !== undefined;
      var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");
      return supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;
  }
  var fixed = function(element:any) {
      var onscroll = function() {
          if (scrollY() > 210) {
              element.classList.remove('top')
              element.classList.add('fixed')
          } else {
              element.classList.remove('fixed')
              element.classList.add('top')
              
          }
      }
      window.addEventListener('scroll', onscroll)
  }
  var element = document.querySelector(`.${arg}`)
  fixed(element)
}
