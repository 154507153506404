import React from "react";
import { Link } from "react-router-dom";

const Body = () => {
  return (
    <div>
      <h1 id="title">1 - Édition du site</h1>
      <p>
        En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
        confiance dans l'économie numérique, il est précisé aux utilisateurs du
        site internet https://www.kaba-delivery.com/ l'identité des différents
        intervenants dans le cadre de sa réalisation et de son suivi:
      </p>
      <p>
        Propriétaire du site : KABA DELIVERY <br />
        Contact : contact@kaba-delivery.com 92109474 <br />
        Adresse : 28BP56.
      </p>
      <p>
        Identification de l'entreprise : SARL KABA DELIVERY au capital social de
        24427€ <br />
        SIREN : - RCS ou RM : TG-LOM 2018B2001 <br />
        CGU:{" "}
        <Link to="/conditions-generales-utilisations#CGU">
          {" "}
          https://www.kaba-delivery.com/conditions-generales-utilisations#CGU
        </Link>
        
      </p>
      <p>Directeur de la publication : KABA DELIVERY - Contact : 90628725.</p>
      <p>Hébergeur : Cloudfront https://aws.amazon.com/fr/cloudfront/</p>
      <p>
        Délégué à la protection des données : Ulrich ABIGUIME -
        a.ulrich@kaba-delivery.com
      </p>
      <h1 id="title">2 - Propriété intellectuelle et contrefaçons.</h1>
      <p>
        KABA DELIVERY est propriétaire des droits de propriété intellectuelle et
        détient les droits d’usage sur tous les éléments accessibles sur le site
        internet, notamment les textes, images, graphismes, logos, vidéos,
        architecture, icônes et sons.
      </p>
      <p>
        Toute reproduction, représentation, modification, publication,
        adaptation de tout ou partie des éléments du site, quel que soit le
        moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
        préalable de KABA DELIVERY.
      </p>
      <p>
        Toute exploitation non autorisée du site ou de l’un quelconque des
        éléments qu’il contient sera considérée comme constitutive d’une
        contrefaçon et poursuivie conformément aux dispositions des articles
        L.335-2 et suivants du Code de Propriété Intellectuelle.
      </p>
      <h1 id="title">3 - Limitations de responsabilité.</h1>
      <p>
        KABA DELIVERY ne pourra être tenu pour responsable des dommages directs
        et indirects causés au matériel de l’utilisateur, lors de l’accès au
        site  <Link to="/#top">
          {" "}
          https://www.kaba-delivery.com
        </Link>
      </p>
      <p>
        KABA DELIVERY décline toute responsabilité quant à l’utilisation qui
        pourrait être faite des informations et contenus présents sur
        <Link to="/#top">
          {" "}
          https://www.kaba-delivery.com
        </Link>
      </p>
      <p>
        KABA DELIVERY s’engage à sécuriser au mieux le site
        <Link to="/#top">
          {" "}
          https://www.kaba-delivery.com
        </Link>, cependant sa responsabilité ne pourra
        être mise en cause si des données indésirables sont importées et
        installées sur son site à son insu
      </p>
      <p>
        Des espaces interactifs (espace contact ou commentaires) sont à la
        disposition des utilisateurs. KABA DELIVERY se réserve le droit de
        supprimer, sans mise en demeure préalable, tout contenu déposé dans cet
        espace qui contreviendrait à la législation applicable en France, en
        particulier aux dispositions relatives à la protection des données.
      </p>
      <p>
        Le cas échéant, KABA DELIVERY se réserve également la possibilité de
        mettre en cause la responsabilité civile et/ou pénale de l’utilisateur,
        notamment en cas de message à caractère raciste, injurieux, diffamant,
        ou pornographique, quel que soit le support utilisé (texte, photographie
        …).
      </p>
      <h1 id="title">4 - CNIL et gestion des données personnelles.</h1>
      <p>
        Conformément aux dispositions de la loi 78-17 du 6 janvier 1978
        modifiée, l’utilisateur du site  <Link to="/#top">
          {" "}
          https://www.kaba-delivery.com
        </Link> dispose
        d’un droit d’accès, de modification et de suppression des informations
        collectées. Pour exercer ce droit, envoyez un message à notre Délégué à
        la Protection des Données : Ulrich ABIGUIME -
        a.ulrich@kaba-delivery.com.
      </p>
      <p>
        Pour plus d'informations sur la façon dont nous traitons vos données
        (type de données, finalité, destinataire...), lisez notre
        <Link to="/conditions-generales-utilisations#CGU">
          {" "}
          https://www.kaba-delivery.com/conditions-generales-utilisations#CGU
        </Link>
      </p>
      <h1 id="title">5 - Liens hypertextes et cookies</h1>
      <p>
        Le site  <Link to="/#top">
          {" "}
          https://www.kaba-delivery.com
        </Link> contient des liens hypertextes
        vers d’autres sites et dégage toute responsabilité à propos de ces liens
        externes ou des liens créés par d’autres sites vers
        <Link to="/#top">
          {" "}
          https://www.kaba-delivery.com
        </Link>/.
      </p>
      <p>
        La navigation sur le site <Link to="/#top">
          {" "}
          https://www.kaba-delivery.com
        </Link> est susceptible
        de provoquer l’installation de cookie(s) sur l’ordinateur de
        l’utilisateur.
      </p>
      <p>
        Un "cookie" est un fichier de petite taille qui enregistre des
        informations relatives à la navigation d’un utilisateur sur un site. Les
        données ainsi obtenues permettent d'obtenir des mesures de
        fréquentation, par exemple.
      </p>
      <p>
        Vous avez la possibilité d’accepter ou de refuser les cookies en
        modifiant les paramètres de votre navigateur. Aucun cookie ne sera
        déposé sans votre consentement.
      </p>
      <p>Les cookies sont enregistrés pour une durée maximale de 1 mois.</p>
      <p>
        Pour plus d'informations sur la façon dont nous faisons usage des
        cookies, lisez notre
        <Link to="/conditions-generales-utilisations#CGU">
          {" "}
          https://www.kaba-delivery.com/conditions-generales-utilisations#CGU
        </Link>
      </p>
      <p></p>

      <h1 id="title">6 - Droit applicable et attribution de juridiction.</h1>

      <p>
        Tout litige en relation avec l’utilisation du site
        <Link to="/#top">
          {" "}
          https://www.kaba-delivery.com
        </Link> est soumis au droit français. En dehors
        des cas où la loi ne le permet pas, il est fait attribution exclusive de
        juridiction aux tribunaux compétents de Lomé, Togo.
      </p>
    </div>
  );
};

export default Body;
