import React from 'react';
import Wrapper1 from '../components/global/Wrapper1';
import Body from '../components/kaba-PC/Body';

const KabaPC = () => {
  return (
    <div>
     
      <Wrapper1
        name="PC"
        bannerTitle='POLITIQUE DE CONFIDENTIALITE'
        content={ <Body/>}
        />
    </div>
  );
};

export default KabaPC;